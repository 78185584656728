import React, {Component} from "react";
import './Blackboard.css'
import {withTranslation} from "react-i18next";
import ToolWindow from "../ToolWindow/ToolWindow";
import {Map} from "immutable";

class Blackboard extends Component {

    state = {
        toolProps: Map(
            {
                tool: null,
                toolColor: '#df221d',
                toolSize: 10,
            }
        ),
        showTools: true,

    }
    isPainting = false;
    line = [];
    // userId = this.props.user.get('id');
    prevPos = {offsetX: 0, offsetY: 0};

    constructor(props) {
        super(props);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onPointerEnter = this.onPointerEnter.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.endPaintEvent = this.endPaintEvent.bind(this);
        this.resizeCanvas = this.resizeCanvas.bind(this);

    }

    onToolPropsChange = (key, value) => {
        this.setState(({toolProps}) => ({toolProps: toolProps.update(key, val => value)}));
    }

    showTools = () => {
        this.setState((state) => ({showTools: !state.showTools}));
    }

    onClearAll = () => {
        this.line = [];
        let width = this.ctx.canvas.width;
        let height = this.ctx.canvas.height;
        this.ctx.clearRect(0, 0, width, height);
        this.sendPaintData([]);
    }


    /**
     * Imposto la posizione di inizio disegno
     * @param nativeEvent
     */
    onMouseDown({nativeEvent}) {
        let offsetX = null;
        let offsetY = null;

        if (nativeEvent instanceof MouseEvent) {
            offsetX = nativeEvent.offsetX;
            offsetY = nativeEvent.offsetY;
        } else if (nativeEvent instanceof TouchEvent) {
            offsetX = nativeEvent.targetTouches[0].clientX;
            offsetY = nativeEvent.targetTouches[0].clientY;
        }
        this.isPainting = true;
        this.prevPos = {offsetX, offsetY};
    }

    /**
     * Imposto la posizione di inizio disegno
     * @param nativeEvent
     */
    onPointerEnter({nativeEvent}) {
        const {offsetX, offsetY} = nativeEvent;
        if (nativeEvent.buttons !== 1) {
            this.isPainting = false;

        }
        // this.isPainting = true;
        // this.prevPos = {offsetX, offsetY};
    }

    /**
     * Se sto premendo il pulsante inizio a disegnare
     * @param nativeEvent
     */
    onMouseMove({nativeEvent}) {
        if (this.isPainting && this.state.toolProps.get('tool') !== null) {
            let offsetX = null;
            let offsetY = null;

            if (nativeEvent instanceof MouseEvent) {
                offsetX = nativeEvent.offsetX;
                offsetY = nativeEvent.offsetY;
            } else if (nativeEvent instanceof TouchEvent) {
                offsetX = nativeEvent.targetTouches[0].clientX;
                offsetY = nativeEvent.targetTouches[0].clientY;
            }
            // const {offsetX, offsetY} = nativeEvent;
            const offSetData = {offsetX, offsetY};
            // Creo la struttura del segmento
            const positionData = {
                start: {...this.prevPos},
                stop: {...offSetData},
                color: this.state.toolProps.get('toolColor'),
                size: this.state.toolProps.get('toolSize'),
                tool: 'line'
            };
            // Aggiungo la posizone all'array
            this.line = this.line.concat(positionData);
            // Funzione di disegno
            this.paint(this.prevPos, offSetData);
        }
    }


    /**
     * Funzione richiamata onUp del mouse o al onLeave del puntatore
     * Fine disegno e invio dei dati
     */
    endPaintEvent() {
        if (this.isPainting) {
            this.isPainting = false;
            if (this.line.length > 0) {
                this.sendPaintData(this.line);
            }
        }
    }


    paint(prevPos, currPos) {
        const {offsetX, offsetY} = currPos;
        const {offsetX: x, offsetY: y} = prevPos;

        this.ctx.beginPath();

        this.ctx.lineWidth = this.state.toolProps.get('toolSize');
        // Size of draw
        this.ctx.strokeStyle = this.state.toolProps.get('toolColor');

        // Move the the prevPosition of the mouse
        this.ctx.moveTo(x, y);
        // Draw a line to the current position of the mouse
        this.ctx.lineTo(offsetX, offsetY);
        if (this.state.toolProps.get('tool') === 1) {
            // Visualize the line using the strokeStyle
            this.ctx.globalCompositeOperation = "source-over";

            this.ctx.stroke();
        } else if (this.state.toolProps.get('tool') === 2) {
            this.ctx.globalCompositeOperation = "destination-out";
            // this.ctx.arc(offsetX,offsetY,8,0,Math.PI*2,false);
            this.ctx.stroke();

        }
        //  // Visualize the line using the strokeStyle
        // this.ctx.stroke();
        this.prevPos = {offsetX, offsetY};
    }

    async sendPaintData(line) {
        const body = {
            data: line,
            width: this.canvas.width,
            height: this.canvas.height,
            ratio: this.props.ratio,
            to: this.props.subCall.get('contactFrom'),
        };
        this.props.socketEmit('whiteboard', body)
        // We use the native fetch API to make requests to the server
        // const req = await fetch('http://localhost:4000/paint', {
        //     method: 'post',
        //     body: JSON.stringify(body),
        //     headers: {
        //         'content-type': 'application/json',
        //     },
        // });
        // const res = await req.json();
        this.line = [];
    }

    componentDidMount() {
        this.resizeCanvas()
    }

    resizeCanvas() {
        if (this.canvas) {
            this.canvas.width = this.props.width ? this.props.width : 0;
            this.canvas.height = this.props.height ? this.props.height : 0;
            // this.canvas.height = window.innerHeight - 152;
            this.ctx = this.canvas.getContext('2d');
            this.ctx.lineJoin = 'round';
            this.ctx.lineCap = 'round';
            this.sendPaintData([]);
            // this.line.map((value, index) => {
            //     this.paint(value.start, value.stop)
            // })

        }
    }


    render() {


        if (this.canvas && (Math.trunc(this.canvas.width) !== Math.trunc(this.props.width) || Math.trunc(this.canvas.height) !== Math.trunc(this.props.height))) {
            this.resizeCanvas();
        }

        return (
            <React.Fragment>
                <canvas
                    className={'blackboard'}
                    ref={(ref) => (this.canvas = ref)}
                    style={{background: 'transparent'}}
                    onMouseDown={this.onMouseDown}
                    // onMouseLeave={this.endPaintEvent}
                    onMouseUp={this.endPaintEvent}
                    onMouseMove={this.onMouseMove}
                    onPointerEnter={this.onPointerEnter}
                    onTouchMove={this.onMouseMove}
                    onTouchStart={this.onMouseDown}
                    onTouchEnd={this.endPaintEvent}

                />
                <ToolWindow toolProps={this.state.toolProps}
                            onToolPropsChange={(key, value) => this.onToolPropsChange(key, value)}
                            clearAll={this.onClearAll}
                            showTools={this.showTools}
                            visible={this.state.showTools}
                />
            </React.Fragment>
        );
    }

    // render() {
    //     return (
    //         <div className={"blackboard"}>
    //
    //         </div>
    //     );
    // }
}

export default withTranslation()(Blackboard);