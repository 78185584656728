import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import './GuestSettings.css'
import Divider from "@material-ui/core/Divider";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Button from "@material-ui/core/Button";
import {Map} from 'immutable'
import {connect} from "react-redux";
import {changeUserPassword, updateUser} from "../../store/actions/auth";
import LoopIcon from "@material-ui/icons/Loop";
import RefreshButton from "../library/IconTpButton/IconTpButton";

class GuestSettings extends Component {

    state = {
        user: Map({}),
        password: Map({
            old_password: '',
            new_password_1: '',
            new_password_2: ''
        })
    }

    refreshData = () => {

    }

    componentDidMount() {
        this.setState({user: this.props.user})
    }


    render() {

        const {t} = this.props

        const onChangePasswordValue = (key, value) => {
            this.setState(({password}) => ({password: password.update(key, v => value)}))
        }

        const onChangeUserValue = (key, value) => {
            this.setState(({user}) => ({user: user.update(key, v => value)}))
        }

        return (
            <div className={'guest_account'}>
                <div className={'header_bar'}>
                    <Typography variant={'h4'}>Account</Typography>
                    <RefreshButton onClick={this.refreshData} icon={LoopIcon}/>

                </div>
                <Divider variant={"fullWidth"}/>

                <div className={'account_container'}>

                    <div className={'change_password'}>
                        <VpnKeyIcon className={'icon'}/>
                        <Typography variant={'h4'}>
                            {t('Generali')}
                        </Typography>
                        <TextField
                            className={'textField_password'}
                            variant={"outlined"}
                            label={t('Nome')}
                            value={this.state.user.get('name')}
                            onChange={(event) => onChangeUserValue('name', event.target.value)}
                        />
                        <TextField
                            className={'textField_password'}
                            variant={"outlined"}
                            label={t('Cognome')}
                            value={this.state.user.get('surname')}
                            onChange={(event) => onChangeUserValue('surname', event.target.value)}
                        />
                        <TextField
                            className={'textField_password'}
                            variant={"outlined"}
                            label={t('Email')}
                            value={this.state.user.get('email')}
                            onChange={(event) => onChangeUserValue('email', event.target.value)}
                        />
                        <Button
                            variant={"contained"}
                            style={{margin: '25px 0 0 180px', width: '140px'}}
                            onClick={() => this.props.updateUser(this.state.user)}
                        >
                            {t('Salva')}
                        </Button>
                    </div>
                    <Divider variant={"fullWidth"}/>
                </div>

                <div className={'account_container'}>

                    <div className={'change_password'}>
                        <VpnKeyIcon className={'icon'}/>
                        <Typography variant={'h4'}>
                            {t('Cambio password')}
                        </Typography>
                        <TextField
                            className={'textField_password'}
                            variant={"outlined"}
                            label={t('Vecchia password')}
                            type={'password'}
                            value={this.state.password.get('old_password')}
                            onChange={(event) => onChangePasswordValue('old_password', event.target.value)}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            // error={this.state.password.get('old_password', '').length < 8 && this.state.password.get('old_password')}
                            // helperText={t('Lunghezza minima 8 caratteri')}
                        />
                        <TextField
                            className={'textField_password'}
                            variant={"outlined"}
                            label={t('Nuova password')}
                            type={'password'}
                            value={this.state.password.get('new_password_1')}
                            onChange={(event) => onChangePasswordValue('new_password_1', event.target.value)}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            error={this.state.password.get('new_password_1', '').length < 8 && this.state.password.get('new_password_1')}
                            helperText={this.state.password.get('new_password_1', '').length < 8 && this.state.password.get('new_password_1', '').length > 0? t('Lunghezza minima 8 caratteri') : ''}
                        />
                        <TextField
                            className={'textField_password'}
                            variant={"outlined"}
                            label={t('Ripeti nuova password')}
                            type={'password'}
                            value={this.state.password.get('new_password_2')}
                            onChange={(event) => onChangePasswordValue('new_password_2', event.target.value)}
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            error={
                                this.state.password.get('new_password_1') !== this.state.password.get('new_password_2') &&
                                this.state.password.get('new_password_2') !== '' ||
                                this.state.password.get('new_password_2', '').length < 8 &&
                                this.state.password.get('new_password_2')}
                            helperText={
                                this.state.password.get('new_password_2', '').length < 8 &&
                                this.state.password.get('new_password_2', '').length > 0 ?
                                    t('Lunghezza minima 8 caratteri') :
                                    this.state.password.get('new_password_2', '') !== this.state.password.get('new_password_1', '') ?
                                        t('Le due password non corrispondono') :
                                        ''}
                        />
                        <Button
                            variant={"contained"}
                            style={{margin: '25px 0 0 180px', width: '140px'}}
                            onClick={() => this.props.changeUserPassword(this.state.user.get('id'), this.state.password.get('old_password'), this.state.password.get('new_password_1'), this.state.password.get('new_password_2'))}
                            disabled={this.state.password.get('new_password_1') !== this.state.password.get('new_password_2') || !this.state.password.get('old_password') || this.state.password.get('new_password_1', '').length < 8}
                        >
                            {t('Salva')}
                        </Button>
                    </div>
                    {/*<Divider variant={"fullWidth"}/>*/}


                </div>

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeUserPassword: (...args) => dispatch(changeUserPassword(...args)),
        updateUser: (...args) => dispatch(updateUser(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GuestSettings));