import axios from 'axios';
import {basePath} from "./config";

const instance = axios.create({
    baseURL : process.env.REACT_APP_BASE_URL,
    headers : {
        'Content-Type': 'application/json'
    }
});

// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';
// instance.defaults.headers.common['Content-Type'] = 'application/json';

export default instance;