import React, {Component} from "react";
import {ButtonBase, Dialog, Paper} from "@material-ui/core";
import './PopUpGuestCall.css'
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {Map} from "immutable";
import {withTranslation} from "react-i18next";
import Blackboard from "../Blackboard/Blackboard";
import CallAction from "../callAction/CallAction";
import {chatSubscribe, getLocalStream, HangUpCall, muteMic, subscribe} from "../../../store/actions/calls";
import SmartGlasses from "../Icon/SmartGlasses";
import StreaMaze from "../Icon/StreamazeIcon";
import ExternalCamera from "../Icon/ExternalCamera";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Chat from "../ChatV2/Chat";
import {socketEmit} from "../../../store/actions/socketIO";
import * as actionType from "../../../store/actions/actions";
import moment from "moment";

class PopUpGuestCall extends Component {


    state = {
        onWaitingVideo: true,
        setUserVideo: false,
        videoFilter: Map({
            brightness: 100,
            contrast: 100,
            saturation: 100,
        }),
        actLocalStream: 0,
        actSource: null,
        canvasHeight: 0,
        canvasWidth: 0,
        videoRatio: 0,
        callDuration: null
        // micMute: false,
    }


    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.videoRefUser = React.createRef();
        this.videoRefMainDev = React.createRef();
        this.actMainStream = null
        this.actSource = null
        this.lastSource = null;

        // this.onVideoReady = this.onVideoReady.bind(this);
    }

    // componentDidMount() {
    //     this.props.chatSubscribe(this.handleNewUserMessage)
    // }

    componentDidMount() {
        window.addEventListener('resize', this.onVideoReady, false);
        this.callUpdater = setInterval(this.updateCallDuration, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.callUpdater)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.videoRef.current && !this.videoRef.current.setEv) {
            console.debug('added Event')
            this.videoRef.current.addEventListener('loadeddata', () => this.onVideoReady())
            this.videoRef.current.setEv = true

        }
        console.log("feed: ", this.props.feed, "lastSource: ", this.lastSource)

        if(this.props.feed !== null && this.props.subcalls !== null && this.props.feed !== this.lastSource) {
            console.log("subcalls", this.props.subcalls)
            const sb = this.props.subcalls.find(s => s.get('id') === this.props.feed)
            console.log(sb)
            this.onSourceSubscribe(sb)
        }
    }

    onVideoFilterChange = (key, value) => {
        this.setState(oldState => ({videoFilter: oldState.videoFilter.update(key, val => value)}))
    }

    resetFilter = () => {
        this.setState({
            videoFilter: Map({
                brightness: 100,
                contrast: 100,
                saturation: 100,
            })
        })
    }

    onMicMute = () => {
        this.props.subcall.mute(this.state.actLocalStream)
        // this.setState(oldState => ({micMute: !oldState.micMute}))
    }

    onExitedPopUp = () => {
        this.actSource= null

        this.setState({
            onWaitingVideo: true,
            // actSource: null,
            setUserVideo: false,
            canvasHeight: 0,
            canvasWidth: 0,
            videoRatio: 0
        })
    }

    updateCallDuration = () => {
        if(this.props.subcalls){
            const currentDate = moment();
            const startDate = moment(this.props.subcalls.getIn([0, 'tsStart']),'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
            if(startDate.isValid()){
                const dur = moment.utc(currentDate.diff(startDate)).format('HH:mm:ss')
                this.setState({callDuration: dur})
            }

        }
    }

    setStream = (element, stream) => {
        if (void 0 === element) {
            return;
        }
        if (void 0 !== element.srcObject) {
            element.srcObject = stream;
        } else {
            if (void 0 !== element.mozSrcObject) {
                element.mozSrcObject = stream
            } else {
                if (void 0 !== element.src) {
                    try {
                        // eslint-disable-next-line no-undef
                        element.src = webkitURL.createObjectURL(stream);
                    } catch (e) {
                        element.src = URL.createObjectURL(stream);
                    }
                } else {
                    console.error("Video element is null");
                    return false;
                }
            }
        }
    }

    onSourceSubscribe = (s) => {

        console.debug("source subscribe", s);
        console.debug(this.actSource)
        console.debug(this.props.subcalls.getIn([1, 'id']))
        if (s.get('id') === this.actSource){
            console.info('Già selezionato')
        }
        else if (s.get('id') === this.props.subcalls.getIn([1, 'id'])){  // main device subcall
            this.props.subscribe(this.actSource, true)  //unsubscribe last feed
            this.actSource= null
            this.setState({onWaitingVideo: true, }) //actSource: null
            this.setStream(this.videoRef.current, null)
            this.props.updateFeed(s.get('id'))
            this.props.subscribe(s.get('id'), false)  // subscribe main device audio/video
        }
        else {
            // subscribe altro feed mantenendo solo audio main device
            this.props.subscribe(this.actSource, false, false, true)  //subscribe solo audio
            this.actSource= null
            this.setState({onWaitingVideo: true, }) //actSource: null
            this.setStream(this.videoRef.current, null)
            this.props.updateFeed(s.get('id'))
            this.props.subscribe(s.get('id'), false)
        }

        this.lastSource = this.props.feed; // set lastSource con il feed corrente quando viene avviato il video
        this.actSource = s.get('id')
        this.setState({
            // actSource: s.get('id'),
            canvasHeight: 0,
            canvasWidth: 0,
            videoRatio: 0
        })

        // this.props.subscribe(s.get('id'), false)

        if (s.get('id') !== this.props.subcalls.getIn([1, 'id'])) {
            console.debug('set main', this.props.receiverStreams[this.props.subcalls.getIn([1, 'id'])], this.props.subcalls.getIn([1, 'id']))
            this.setStream(this.videoRefMainDev.current, this.props.receiverStreams[this.props.subcalls.getIn([1, 'id'])])
        }
    }

    HangUpCall = () => {
        // if (this.userSource) {
        //     this.userSource.getTracks().forEach(t => t.stop())
        // }
        this.props.HangUpCall()
    }

    onVideoReady = () => {
        if (!this.props.receiverStreams[this.actSource]) return
        if (!this.videoRef.current) return
        let ratio = this.props.receiverStreams[this.actSource].getVideoTracks()[0].getSettings().aspectRatio
        let widthStream = ratio * this.videoRef.current.clientHeight
        let heightStream = this.videoRef.current.clientHeight
        console.debug('widthStream', widthStream)
        if (widthStream >= this.videoRef.current.clientWidth) {
            console.info('Tocca ai lati')
            heightStream = this.videoRef.current.clientWidth / ratio
            widthStream = this.videoRef.current.clientWidth
        }
        console.info(widthStream)
        console.info(heightStream)

        this.setState({
            canvasHeight: heightStream,
            canvasWidth: widthStream,
            videoRatio: ratio
        })
    }

    render() {
        const {t} = this.props


        let FromContact = null
        // let FromContactGroup = Map({})
        if (this.props.call_request) {
            FromContact = this.props.contacts.find(c => c.id === this.props.call_request.contactFrom)
            // FromContactGroup = this.props.container.getIn(['groups', this.props.container.get('groups', List([])).findIndex(val => val.get('id') === FromContact.relatedContainer)])
        }


        // if (this.props.streams) {
        //     console.log(this.props.streams)
        //     if (this.videoRef.current && this.state.onWaitingVideo) {
        //         this.setState({onWaitingVideo: false})
        //         this.setStream(this.videoRef.current, this.props.streams[0])
        //         this.props.streams.map((strm, index) => console.log(strm.getTracks()))
        //     }
        // }

        if (this.props.subcalls && this.videoRef.current) {
            // if(this.props.feed !== this.actSource) this.actSource = this.props.feed;

            console.debug(this.videoRef.current.readyState)
            if (this.videoRef.current.readyState === 0) {
                if ((this.actSource || this.props.subcalls.getIn([1, 'state']) === 3) && this.props.receiverStreams) {

                    let act_source = this.actSource

                    if (!act_source &&
                        !Object.keys(this.props.receiverStreams).includes(this.props.subcalls.getIn([1, 'id']))
                    ) {
                        act_source = this.props.subcalls.getIn([1, 'id'])
                        // this.actSource= act_source
                        // this.setState({actSource: act_source})
                        this.onSourceSubscribe(this.props.subcalls.getIn([1]))
                    }
                    this.setStream(this.videoRef.current, this.props.receiverStreams[act_source])
                    this.actMainStream = this.props.receiverStreams[act_source]
                    console.info('Sorgente selezionata', act_source)
                    console.debug('onWaitingVideo', this.state.onWaitingVideo)
                    if (this.state.onWaitingVideo && Object.keys(this.props.receiverStreams).includes(act_source.toString())) {
                        console.info('set stream', this.props.receiverStreams[act_source])
                        this.setState({onWaitingVideo: false})
                    }

                    // Object.keys(this.props.receiverStreams).forEach(v => {
                    //     if (this.actSource == v && this.state.onWaitingVideo) {
                    //         console.info('set stream', this.props.receiverStreams[v])
                    //         this.setState({onWaitingVideo: false})
                    //         this.setStream(this.videoRef.current, this.props.receiverStreams[v])
                    //     }
                    // })

                }
            }
        }

        // console.log(this.videoRefUser.current)
        // console.log(this.props.senderStreams)
        if (this.props.senderStreams && this.videoRefUser.current && !this.state.setUserVideo) {
            // this.userSource = this.props.senderStreams.clone()
            // this.userSource.getAudioTracks().map(t => t.stop())
            console.info('set stream user',)
            this.setState({setUserVideo: true})

            this.setStream(this.videoRefUser.current, this.props.senderStreams)
        }


        let waitngCall = null
        if (this.state.onWaitingVideo) {
            waitngCall = (<div className={'waitingCall'}>
                <div className="container">
                    <div className="ball ball-one"/>
                    <div className="ball ball-two"/>
                    <div className="ball ball-three"/>
                    <div className="ball ball-four"/>
                    <div className="shadow shadow-one"/>
                    <div className="shadow shadow-two"/>
                    <div className="shadow shadow-three"/>
                    <div className="shadow shadow-four"/>
                </div>
                <Typography variant={'h4'}>
                    {t("Chiamata in corso")}
                </Typography>
            </div>)
        }

        let subcalls = null
        if (this.props.subcalls && this.props.contacts) {
            subcalls = this.props.subcalls.filter((s, i) => i !== 0).map((s, index) => {
                const cnt = this.props.contacts.get(this.props.contacts.findIndex(c => c.get('id') === s.get('contactFrom')))
                let device_icon
                if (cnt.get('type') === 'SG') {
                    device_icon = <SmartGlasses style={{color: '#FFF', height: '40px', width: '48px'}}/>
                } else if (cnt.get('type') === 'SA') {
                    device_icon = <StreaMaze style={{color: '#FFF', height: '48px', width: '48px'}}/>
                } else if (cnt.get('type') === 'EC') {
                    device_icon = <ExternalCamera style={{color: '#FFF', height: '48px', width: '48px'}}/>
                } else if (cnt.get('type') === 'SP') {
                    device_icon = <PhoneAndroidIcon style={{color: '#FFF', height: '48px', width: '48px'}}/>
                } else {
                    device_icon = <HelpOutlineIcon style={{color: '#FFF', height: '48px', width: '48px'}}/>
                }
                let classname
                if (s.get('state') !== 3) {
                    classname = 'outerSource sourceOffline'
                } else if (s.get('state') === 3 && this.actSource === s.get('id')) {
                    classname = 'outerSource sourceSelected'
                } else {
                    classname = 'outerSource sourceAvailable'
                }
                return (
                    // <div style={{display:"flex", flexDirection:"column"}}>
                    <ButtonBase
                        key={s.get('id')}
                        className={classname}
                        disabled={s.get('state') !== 3}
                    >
                        <Paper className={'innerSource'}
                               variant={"outlined"}
                               onClick={() => this.onSourceSubscribe(s)}
                        >
                            {device_icon}
                            <Typography>{cnt.get('name')}</Typography>
                        </Paper>

                    </ButtonBase>
                    //<ButtonBase
                    //    key={s.get('id') + '2' }
                    //    className={classname}
                    //    disabled={s.get('state') !== 3}
                    //>
                    //    <Paper
                    //        className={'innerSource'}
                    //        variant={"outlined"}
                    //        onClick={() => this.onSourceSubscribe(s)}
                    //    >
                    //        {device_icon}
                    //        <Typography>{cnt.get('name')}</Typography>
                    //    </Paper>
                    //</ButtonBase>
                    // </div>
                )
            })
        }
        //
        // if (Boolean((this.props.call_request || Map({})).get('state') === 2) && this.props.senderStreams === undefined) {
        //     this.props.getLocalStream()
        // }


        console.debug(this.props.senderStreamsMuted)
        // console.debug(this.props.senderStreams.getAudioTracks().some(c => c.muted))

        return (
            <Dialog open={this.props.open && Boolean((this.props.call_request || Map({})).get('state') === 2)}
                    className={'PopUpGuestCall'}
                    maxWidth={"xl"} fullWidth
                    onExited={this.onExitedPopUp}

            >
                <div className={'PopUpBody'}>
                    <div className={'PopUpHeader'}>
                        <Avatar className={'PopUpAvatar'}/>
                        <Typography variant={"h5"}
                                    className={'popUpContactName'}>{this.props.call_request.get('contactToFullName')}</Typography>
                        {/*<Typography variant={"subtitle2"}*/}
                        {/*            className={'contGroupInfo'}>{props.container.get('title', t('nome container'))} - {FromContactGroup.get('title', t('nome gruppo'))}</Typography>*/}
                        {/*<Typography variant={"subtitle2"}*/}
                        {/*            className={'callDescription'}>{props.callRequest.description}</Typography>*/}
                        <Typography variant={"h6"}>{this.state.callDuration}</Typography>
                    </div>

                    <div className={'PopUpContent'}>

                        <video
                            ref={this.videoRef} autoPlay
                            style={{filter: `brightness(${this.state.videoFilter.get('brightness')}%) contrast(${this.state.videoFilter.get('contrast')}%) saturate(${this.state.videoFilter.get('saturation')}%)`}}
                            className={'video'}
                        />

                        <video ref={this.videoRefUser} autoPlay muted
                            // style={{filter: `brightness(${this.state.videoFilter.get('brightness')}%) contrast(${this.state.videoFilter.get('contrast')}%) saturate(${this.state.videoFilter.get('saturation')}%)`}}
                               className={'userSource'}
                               style={{
                                   visibility: this.state.setUserVideo ? 'visible' : 'hidden',
                                   transform: 'scale(-1, 1)'
                               }}
                        />

                        {/*{*/}
                        {/*    this.props.subcalls && this.actSource !== this.props.subcalls.getIn([1, 'id']) &&*/}
                        <video
                            ref={this.videoRefMainDev} autoPlay
                            // style={{filter: `brightness(${this.state.videoFilter.get('brightness')}%) contrast(${this.state.videoFilter.get('contrast')}%) saturate(${this.state.videoFilter.get('saturation')}%)`}}
                            className={'mainDevSource'}
                            style={{visibility: 'hidden'}}
                        />
                        {/*}*/}

                        {waitngCall}

                        {/*<Widget*/}
                        {/*    handleNewUserMessage={this.handleNewUserMessage}*/}
                        {/*    profileAvatar={logo}*/}
                        {/*    color={'#FF0000'}*/}
                        {/*    title="My new awesome title"*/}
                        {/*    subtitle="And my cool subtitle"*/}
                        {/*    showTimeStamp={true}*/}
                        {/*    badge={3}*/}
                        {/*/>*/}
                        <div className={'chatPosition'}>

                            <Chat

                            />
                        </div>
                        <CallAction
                            videoFilter={this.state.videoFilter}
                            micMute={this.props.senderStreamsMuted}
                            onChange={(...args) => this.onVideoFilterChange(...args)}
                            onMicMute={(...args) => this.props.muteMic(...args)}
                            hangUp={() => this.HangUpCall()}
                            resetFilter={this.resetFilter}
                        />
                        <div className={'blackboardPos'}>
                            <Blackboard
                                user={this.props.user}
                                socketEmit={(...args) => this.props.socketEmit(...args)}
                                subCall={this.props.subcalls ? this.props.subcalls.get(1) : Map({})}
                                height={this.state.canvasHeight}
                                width={this.state.canvasWidth}
                                ratio={this.state.videoRatio}
                            />
                        </div>
                        <div className={'subcalls scroller'}>
                            {subcalls}
                        </div>
                    </div>
                </div>


                {/*{props.callRequest === null ? null :*/}
                {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                {/*        {props.callRequest.contactTo === props.session.contact.id ?*/}
                {/*            <div>*/}
                {/*                <button onClick={callAccept}>ACCEPT</button>*/}
                {/*                <button onClick={callDecline}>DECLINE</button>*/}
                {/*            </div> : null}*/}
                {/*        <span>ID: {props.callRequest.id}</span>*/}
                {/*        <span>contactFrom: {props.callRequest.contactFrom}</span>*/}
                {/*        <span>contactTo: {props.callRequest.contactTo}</span>*/}
                {/*        <span>props: {props.callRequest.props}</span>*/}
                {/*        <button onClick={callHangup}>HANGUP</button>*/}
                {/*        /!*{props.callRequest.subcalls !== null ? props.callRequest.subcalls.map(sc => <pre>{sc.id} - {sc.props}</pre>) : null}*!/*/}
                {/*    </div>*/}
                {/*}*/}
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user'),
        session: state.auth.session,
        contacts: state.data.contacts,
        container: state.data.container,
        senderStreams: state.calls.senderStreams,
        receiverStreams: state.calls.receiverStreams,
        subcall: state.calls.subcall,
        subcalls: state.calls.subcalls,
        call_request: state.calls.call_request,
        senderStreamsMuted: state.calls.senderStreamsMuted,
        feed: state.calls.feed
    };
};

const mapDispatchToProps = dispatch => {
    return {
        HangUpCall: (...args) => dispatch(HangUpCall(...args)),
        getLocalStream: (...args) => dispatch(getLocalStream(...args)),
        subscribe: (...args) => dispatch(subscribe(...args)),
        chatSubscribe: (...args) => dispatch(chatSubscribe(...args)),
        socketEmit: (...args) => dispatch(socketEmit(...args)),
        muteMic: (...args) => dispatch(muteMic(...args)),
        updateFeed: (feed) => dispatch({type: actionType.UPDATE_FEED, data: feed})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopUpGuestCall));