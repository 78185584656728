import * as actionType from '../actions/actions'
import {fromJS, List, merge} from "immutable";
import {createQueryString} from "../utility";
import axios_inst from "../../axios_inst";
import {socketOn, socketUnsubscribe} from "./socketIO";
import {enqueueSnackbar} from "./notification";

// region update function
const UpdateCalls = (calls) => {
    return {
        type: actionType.UPDATE_CALLS,
        data: calls
    }
};

const UpdateContainer = (cntr) => {
    return {
        type: actionType.UPDATE_CONTAINER,
        data: cntr
    }
};

const UpdateGroups = (data) => {
    return {
        type: actionType.UPDATE_GROUPS,
        data: data
    }
};

const UpdateContacts = (cnts) => {
    return {
        type: actionType.UPDATE_CONTACTS,
        data: cnts
    }
};

const UpdateDevices = (dev) => {
    return {
        type: actionType.UPDATE_DEVICES,
        data: dev
    }
};

const UpdateUsers = (usr) => {
    return {
        type: actionType.UPDATE_USERS,
        data: usr
    }
};

export const UpdateCallRequest = (data) => {
    return {
        type: actionType.UPDATE_CALL_REQUEST,
        data: data
    }
};

export const UpdateGroupStatistic = (data) => {
    return {
        type: actionType.UPDATE_GROUP_STATISTIC,
        data: data
    }
};
export const UpdateUserStatistic = (data) => {
    return {
        type: actionType.UPDATE_USER_STATISTIC,
        data: data
    }
};

// endregion

// region contacts
/**
 * Get Contatti (device/utenti)
 * @returns
 */
export const getContacts = () => {
    return (dispatch) => {
        let queryObj = {type: ['MEMBER', 'GUEST', 'SG', 'SA', 'EC', 'SP'],}
        const query = createQueryString(queryObj);
        axios_inst.get(`/contacts${query}`)
            .then(res => {
                const cnts = fromJS(res.data.data)
                cnts.forEach(cnt => {
                        dispatch(socketUnsubscribe(`contact/${cnt.get('id')}/update`))
                        dispatch(socketOn(`contact/${cnt.get('id')}/update`, (data) => dispatch(updateContacts(cnt.get('id'), data))))
                    }
                )
                dispatch(UpdateContacts(cnts))
            })
            .catch(error => console.error(error))
    }
}

/**
 * Update dei contatti, eseguito via evento
 * @returns {function(*, *): void}
 */
export const updateContacts = (id, data) => {
    return (dispatch, getState) => {
        const contacts = getState().data.contacts
        const contactsIndex = contacts.findIndex(item => item.get('id') === id)
        if (contacts.getIn([contactsIndex, 'id']) === data.id) {
            dispatch(UpdateContacts(contacts.updateIn([contactsIndex], cnts => cnts.merge(data))))
            console.info(`Contatto aggiornato ${data.id}`)
        } else {
            console.error(`Tentativo di aggiornare il device ${getState().data.contacts.getIn([contactsIndex, 'id'])}, ma i dati si riferiscono a ${data.id}`)
        }
    }
}
// endregion

// region calls
/**
 * Get Chiamate storiche
 * @returns
 */
export const getCalls = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let queryObj = {
                limit: 50,
            }

            const query = createQueryString(queryObj);

            axios_inst.get(`/calls${query}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("calls", data);
                    dispatch(UpdateCalls(data))
                    resolve(data);
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
        })
    }
}
// endregion

// region container
/**
 * Effettua il get del container dell'utente registrato
 * con annessi sottogruppi
 */
export const getContainer = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/containers/${getState().auth.user.getIn(['user', 'relatedContainer'])}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("containers", data);
                    dispatch(UpdateContainer(fromJS(data)))
                    resolve(data);
                })
                .catch(error => reject(error))
        })
    }
}
// endregion

// region gruppi
/**
 * Effettua il get del gruppi dell'utente registrato
 */
export const getGroups = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/containers/${getState().auth.user.getIn(['user', 'relatedContainer'])}/groups`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("groups", data);
                    dispatch(UpdateGroups(fromJS(data)))
                    resolve(data);
                })
                .catch(error => reject(error))
        })
    }
}

export const addGroup = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const groups = getState().data.groups
            axios_inst.post(`containers/${getState().auth.user.getIn(['user', 'relatedContainer'])}/groups`, data)
                .then(res => {
                    const newData = fromJS(res.data.data)
                    console.debug("addGroups", newData);
                    dispatch(UpdateGroups(getState().data.groups.push(newData)))
                    resolve(newData)
                })
                .catch(error => reject(error))
        })
    }
}

export const deleteGroup = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const groups = getState().data.groups
            const groupsId = groups.findIndex(g => g.get('id') === id)
            axios_inst.delete(`containers/${getState().auth.user.getIn(['user', 'relatedContainer'])}/groups/${id}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("delete group", data);
                    dispatch(UpdateGroups(groups.delete(groupsId)))
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}

export const updateGroup = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
                const groups = getState().data.groups
                const groupsId = groups.findIndex(d => d.get('id') === data.get('id'))
                const updatedData = data.filter((v, k) => {
                    if (k === 'description') return true
                    if (k === 'title') return true
                    return groups.getIn([groupsId, k]) !== v
                }).toJS()
                axios_inst.put(`containers/${getState().auth.user.getIn(['user', 'relatedContainer'])}/groups/${data.get('id')}`, updatedData)
                    .then(res => {
                        // const data = fromJS(res.data.data)
                        console.debug("update User", data);
                        dispatch(UpdateGroups(groups.update(groupsId, () => data)))
                        resolve(data)
                    })
                    .catch(error => reject(error))
            }
        )
    }
}
// endregion

// region devices
/**
 * Effettua il get del device
 */
export const getDevices = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            let queryObj = {type: ['SG', 'SA', 'EC', 'SP']}
            const query = createQueryString(queryObj);
            axios_inst.get(`/devices${query}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("devices", data);
                    dispatch(UpdateDevices(fromJS(data)))
                    resolve(data);
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * Update del device
 */
export const updateDevice = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
                const devices = getState().data.devices
                const devId = devices.findIndex(d => d.get('id') === data.get('id'))
                const updatedData = data.filter((v, k) => {
                    if (k === 'id') return true
                    return devices.getIn([devId, k]) !== v
                }).toJS()
                axios_inst.put(`/devices/${updatedData.id}`, updatedData)
                    .then(res => {
                        console.debug("update devices", data);
                        dispatch(UpdateDevices(devices.update(devId, () => data)))
                        resolve(data)
                    })
                    .catch(error => reject(error))

            }
        )
    }
}

/**
 * Eliminazione del device
 * @returns {function(*, *): void}
 */
export const deleteDevice = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const devices = getState().data.devices
            const devId = devices.findIndex(d => d.get('id') === id)

            axios_inst.delete(`/devices/${id}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("delete devices", data);
                    dispatch(UpdateDevices(devices.delete(devId)))
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * Aggiunta camera esterna
 * @param ip
 * @returns {function(*=, *): Promise<unknown>}
 */
export const addNewExternalCamera = (ip) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const devices = getState().data.devices
            const relatedContainer = getState().auth.user.getIn(['user', 'relatedContainer'])
            const assignedGroup = getState().auth.user.getIn(['user', 'assignedGroup'])

            axios_inst.post(`devices`, {
                "containerId": relatedContainer,
                "groupId": assignedGroup,
                "attributes": {
                    "connection": {
                        "type": "rtsp",
                        "url": ip,
                        "user": "",
                        "password": ""
                    }
                },
                // "name": device_name,
                "type": "EC"
            })
                .then(res => {
                    if (!res.data.data) {
                        dispatch(enqueueSnackbar({
                            message: 'F001 - Errore interno creazione device',
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2500,
                            },
                        }))
                        return
                    }
                    const data = fromJS(res.data.data)
                    console.debug("addNewExternalCamera", data);
                    dispatch(UpdateDevices(devices.push(fromJS(data))))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(enqueueSnackbar({
                        message: 'F002 - Errore interno creazione device',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2500,
                        },
                    }))
                    reject(error)
                })
        })
    }
}

/**
 * Pairing device
 * @param device_name
 */
export const pairingSmartGlasses = (device_name, groupId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const devices = getState().data.devices
            const relatedContainer = getState().auth.user.getIn(['user', 'relatedContainer'])
            // const assignedGroup = getState().auth.user.getIn(['user', 'assignedGroup'])

            axios_inst.post(`devices/pairing`,
                {
                    "containerId": relatedContainer,
                    "groupId": groupId,
                    "name": device_name,
                    "type": "SG"
                }
            )
                .then(res => {
                    const data = fromJS(res.data.data.pairCode)
                    console.debug("pairingSmartGlasses", data);
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * Pairing device
 * @param device_name
 */
export const pairingSmartphone = (device_name, groupId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const devices = getState().data.devices
            const relatedContainer = getState().auth.user.getIn(['user', 'relatedContainer'])
            // const assignedGroup = getState().auth.user.getIn(['user', 'assignedGroup'])

            axios_inst.post(`devices/pairing`,
                {
                    "containerId": relatedContainer,
                    "groupId": groupId,
                    "name": device_name,
                    "type": "SP"
                }
            )
                .then(res => {
                    const data = fromJS(res.data.data.pairCode)
                    console.debug("pairingSmartphone", data);
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * Abort Pairing device
 * @param pairCode
 * @returns {Promise<unknown>}
 */
export const abortPairingSmartGlasses = (pairCode) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/devices/pairing/abort`, {"pairCode": pairCode})
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("abortPairingSmartGlasses", data);
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}


/**
 * Aggiunta nuovo SmartGlasses
 * @param newDevice
 * @returns {function(*, *): void}
 */
export const newSmartGlasses = (newDevice) => {
    return (dispatch, getState) => {
        const devices = getState().data.devices
        const data = fromJS(newDevice)
        const idxDev = devices.findIndex(d => d.get('id') === data.get('id'))
        console.debug("addNewSmartGlasses", data);
        if (idxDev >= 0) {
            dispatch(UpdateDevices(devices.update(idxDev, ()=>data)))
        } else {
            dispatch(UpdateDevices(devices.push(data)))
        }
    }
}

/**
 * Device paired events
 * @returns {function(*, *): Promise<unknown>}
 */
export const devicePairedEvents = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(socketUnsubscribe('devices/paired'))
            dispatch(socketOn('devices/paired', ({pairCode, device}) => {
                dispatch(newSmartGlasses(device))
                resolve(pairCode)
            }))
        })
    }
}
// endregion

// region users
/**
 * Effettua il get degli utenti
 */
export const getUsers = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/users`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("user", data);
                    dispatch(UpdateUsers(fromJS(data)))
                    resolve(data);
                })
                .catch(error => reject(error))
        })
    }
}


/**
 * Update degli utenti
 */
export const updateUser = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
                const users = getState().data.users
                const usrId = users.findIndex(d => d.get('id') === data.get('id'))
                const updatedData = data.filter((v, k) => {
                    if (k === 'id') return true
                    return users.getIn([usrId, k]) !== v
                }).toJS()
                axios_inst.put(`users/${updatedData.id}`, updatedData)
                    .then(res => {
                        const data = fromJS(res.data.data)
                        console.debug("update User", data);
                        dispatch(UpdateUsers(users.mergeIn([usrId], updatedData)))
                        resolve(data)
                    })
                    .catch(error => reject(error))

            }
        )
    }
}


/**
 * Eliminazione dell'utente
 * @returns {function(*, *): void}
 */
export const deleteUser = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const users = getState().data.users
            const usrId = users.findIndex(d => d.get('id') === id)
            axios_inst.delete(`users/${id}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("delete user", data);
                    dispatch(UpdateUsers(users.delete(usrId)))
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}


/**
 * Eliminazione utente non ancora registrato
 * @returns {function(*, *): void}
 */
export const deleteUnregisteredUser = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const users = getState().data.users
            const usrId = users.findIndex(d => d.get('id') === id)
            axios_inst.delete(`signup/request/${id}`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("delete unregistered user", data);
                    dispatch(UpdateUsers(users.delete(usrId)))
                    resolve(data)
                })
                .catch(err => {
                    console.error(`/calls`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    // if (err.response) {
                    //     if (err.response.data.code === 412) message = 'Utente non disponibile'
                    // } else {
                    //     message = err.message
                    // }
                    enqueueSnackbar({
                        message: message,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                            autoHideDuration: 2000,
                        },
                    })
                    reject(err)
                })
        })
    }
}


/**
 * Richiesta di aggiunta dell'utente
 * @returns {function(*, *): void}
 */
export const signUpRequest = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const users = getState().data.users
            data['relatedContainer'] = getState().auth.user.getIn(['user', 'relatedContainer'])
            data['assignedGroup'] = parseInt(data['assignedGroup'])
            data['relatedContainer'] = parseInt(data['relatedContainer'])
            axios_inst.post(`signup/request`, data)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("signUpRequest", data);
                    dispatch(getUsers())
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * aggiunta utente
 * @returns {function(*, *): void}
 */
export const signUp = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const users = getState().data.users
            // data['relatedContainer'] = getState().auth.user.getIn(['user', 'relatedContainer'])
            axios_inst.post(`signup`, data)
                .then(res => {
                    const data = fromJS(res.data.data)
                    // console.debug("signUp", data);
                    dispatch(UpdateUsers(users.insert(data)))
                    resolve(data)
                })
                .catch(error => reject(error))
        })
    }
}


export const getSignupData = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/signup/request/${id}`)
                .then(res => {
                    resolve(res.data.data)
                })
                .catch(err => {
                    console.error(`/signup/request`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    // if (err.response) {
                    //     if (err.response.data.code === 412) message = 'Utente non disponibile'
                    // } else {
                    //     message = err.message
                    // }
                    dispatch(enqueueSnackbar({
                            message: message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        })
                    )
                    reject(err)
                })
        })
    }
}

/**
 * Modifica della password dell'utente
 * @param userId
 * @param oldPassword
 * @param newPassword
 * @param newPasswordCopy
 * @returns {Promise<unknown>}
 */
export const changeUserPassword = (userId, oldPassword, newPassword, newPasswordCopy) => {
    // return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        this._axios.post(`passwordChange`,
            {
                "userId": userId,
                "newPassword": newPassword,
                "newPasswordCopy": newPasswordCopy,
                "oldPassword": oldPassword
            }
        )
            .then(res => {
                const dt = res.data.data;
                resolve(dt)
            })
            .catch(error => reject(error))
    })
    // }
}
// endregion


// region Statistic
/**
 * Get dei dati
 */
export const getGroupStatistic = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/containers/${getState().auth.user.getIn(['user', 'relatedContainer'])}/groups/${getState().auth.user.getIn(['user', 'assignedGroup'])}/statistics`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("groupStatistic", data);
                    dispatch(UpdateGroupStatistic(fromJS(data)))
                    resolve(data);
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * Get dei dati
 */
export const getUserStatistic = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.get(`/contacts/${getState().auth.user.getIn(['id'])}/statistics`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("groupStatistic", data);
                    dispatch(UpdateUserStatistic(fromJS(data)))
                    resolve(data);
                })
                .catch(error => reject(error))
        })
    }
}

// endregion
