import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import './Device.css'
import Avatar from "@material-ui/core/Avatar";
import SmartGlasses from "../Icon/SmartGlasses";
import StreaMaze from "../Icon/StreamazeIcon";
import ExternalCamera from "../Icon/ExternalCamera";
import {withTranslation} from "react-i18next";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import batteryStatus from "../utility/batteryStatus";

const Device = ({device, t}) => {

    /**
     *Stato utente
     * Busy => in chiamata
     * Online => se Login eseguito
     */
    let state_contact = '#ffa000'
    const _isOnline = device.getIn(['isOnline'])
    const _isBusy = device.getIn(['isBusy'])
    if (_isOnline && !_isBusy) {
        state_contact = '#43a047'
    } else if (_isOnline && _isBusy) {
        state_contact = '#e53935'
    } else if (!_isOnline && !_isBusy) {
        state_contact = '#ffa000'
    } else if (!_isOnline && _isBusy) {
        state_contact = '#ffa000'
    }

    let device_icon
    let device_type
    const model = device.getIn(['attributes', 'model'])
    if (device.get('type') === 'SG') {
        device_icon = <SmartGlasses style={{color: '#FFF', height: '35px', width: '1000px'}}/>
        device_type = 'Smart Glasses' + (model ? ' - ' + model : '')
    } else if (device.get('type') === 'SA') {
        device_icon = <StreaMaze style={{color: '#FFF', height: '48px', width: '50000px'}}/>
        device_type = 'StreaMaze' + (model ? ' - ' + model : '')
    } else if (device.get('type') === 'EC') {
        device_icon = <ExternalCamera style={{color: '#FFF', height: '48px', width: '50000px'}}/>
        device_type = 'Telecamera esterna' + (model ? ' - ' + model : '')
    } else if (device.get('type') === 'SP') {
        device_icon = <PhoneAndroidIcon style={{color: '#FFF', height: '48px', width: '50000px'}}/>
        device_type = 'Telefono' + (model ? ' - ' + model : '')
    } else {
        device_icon = <HelpOutlineIcon style={{color: '#FFF', height: '48px', width: '50000px'}}/>
        device_type = t('Device sconosciuto')
    }

    let battery = null
    if (device.getIn(['customData', 'battery'])) {
        battery = batteryStatus(device.getIn(['customData', 'battery']), false)
    }


    return (
        <Card className={'device'}>
            <div className={'title'}>
                <div className={'state'} style={{borderColor: state_contact}}>
                    <Avatar className={'avatar'}>
                        {device_icon}
                    </Avatar>
                </div>

            </div>
            <div className={'body_1'}>
                <Typography className={'name'}>{device.get('name', t('Device senza nome')) }</Typography>
                <Typography variant={"subtitle2"} className={'info'}>{device_type}</Typography>
                <Typography variant={"subtitle2"} className={'info'}> id: {device.get('id')}</Typography>

                <div className={'other_states'}>
                    {battery}
                </div>
                {/*<Fab*/}
                {/*    size="large"*/}
                {/*    // color="inherit"*/}
                {/*    aria-label="add"*/}
                {/*    className={'call'}*/}
                {/*    onClick={props.device.sendCallRequest}*/}
                {/*>*/}
                {/*    <CallIcon />*/}
                {/*</Fab>*/}
            </div>


        </Card>

    )


}


export default withTranslation()(Device);