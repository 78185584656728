import React, {PureComponent} from "react";
import './Login.css'
import {withTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {login} from "../../store/actions/auth";
import CircularProgress from "@material-ui/core/CircularProgress";

class Login extends PureComponent {

    state = {
        email: "",
        password: "",
        isLoading: true,
    }

    render() {
        const {t} = this.props;


        const onLoginHandler = (event) => {
            event.preventDefault();
            event.stopPropagation();
            (this.props.login(this.state.email, this.state.password))

            this.setState(({password}) => ({password: ""}))
        }

        const onEmailChange = (event) => {
            this.setState({email: event.target.value})
        }

        const onPasswordChange = (event) => {
            this.setState({password: event.target.value})
        }

        if (this.props.token) {
            return this.props.children
        } else {
            return (
                <div className={'login'}>
                    <Paper variant={"outlined"} className={'container'}>
                        <img src="logo.png" alt="logo" className={'logo'}/>
                        <form className={'form'} onSubmit={onLoginHandler} action="">
                            <TextField
                                color={"primary"}
                                label={t('Email')}
                                type={'email'}
                                variant={"outlined"}
                                margin={"dense"}
                                onChange={onEmailChange}
                                value={this.state.email}
                            />
                            <TextField
                                type={'password'}
                                color={"primary"}
                                label={t('Password')}
                                variant={"outlined"}
                                margin={"dense"}
                                onChange={onPasswordChange}
                                value={this.state.password}

                            />
                            <Button
                                className={'confirm'}
                                variant={"contained"}
                                onClick={onLoginHandler}
                            >
                                {this.props.loading? <CircularProgress style={{height:'24px', width:'24px', margin:'0 10px 0 0 '}}/>: null}
                                {t(`Accedi`)}
                            </Button>
                        </form>
                    </Paper>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.user.get('token'),
        loading: state.auth.loading,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(login(username, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));