import React, {Component} from 'react';
// import './App.css';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Layout from "./Layout/Layout";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PopUpMemberCall from "./components/library/PopUpMemberCall/PopUpMemberCall";
import PopUpGuestCall from "./components/library/PopUpGuestCall/PopUpGuestCall";
import DeviceMng from "./components/DeviceMng/DeviceMng";
import {Redirect} from "react-router";
import axios_inst from "./axios_inst";
import AxiosManager from "./hoc/AxiosManager/AxiosManager";
import {register} from './store/actions/auth'
import {getCalls, getContacts, getContainer, getDevices, getGroups, getUsers} from "./store/actions/data";
import {getPendingCall} from "./store/actions/calls";
import GuestHome from "./components/GuestHome/GuestHome";
import MemberHome from "./components/MemberHome/MemberHome";
import GuestSettings from "./components/GuestSettings/GuestSettings";
import UserMng from "./components/UserMng/UserMng";
import GroupsMng from "./components/GroupsMng/GroupsMng";
import PopUpMemberCallPrg from "./components/library/PopUpMemberCallPrg/PopUpMemberCallPrg";

class App extends Component {

    state = {
        // callRequest: null,
        // contacts: [],
        // contactPolling: null,
        subcall: null,
        streams: [],
        callPopUp: true,
    }

    componentDidMount() {
        this.props.register()
        this.props.getContacts();
        this.props.getCalls();
        this.props.getContainer();
        this.props.getGroups();
        this.props.getUsers();
        this.props.getDevices();
        if (this.props.user.get('type') === 'MEMBER') {
            this.props.getPendingCall();
        //     this.props.subscribeCallRequest()
        }
    }


    render() {
        const {user} = this.props;
        let callPopup = null
        let callRequestPopup = null
        if (user.get('type') === 'MEMBER') {
            callRequestPopup = <PopUpMemberCall
                open={this.state.callPopUp}
                contacts={null}
                />
            callPopup = <PopUpMemberCallPrg/>
        } else if (user.get('type') === 'GUEST') (
            callPopup = <PopUpGuestCall
                open={this.state.callPopUp}
                contacts={null}
            />
        )


        return (
            <Router basename="/">
                {callRequestPopup}
                {callPopup}

                <Layout>
                    {user.get('type') === 'ADMIN' ?
                        <React.Fragment>
                            <Route path="/devices" exact component={() => {
                                return (<DeviceMng/>)
                            }}/>

                            <Route path="/users" exact component={() => {
                                return (<UserMng/>)
                            }}/>

                            <Route path="/groups" exact component={() => {
                                return (<GroupsMng/>)
                            }}/>

                            <Route path="/account" exact component={() => {
                                return (<GuestSettings/>)
                            }}/>

                            <Redirect to={"/devices"}/>
                        </React.Fragment>
                        : null}
                    {user.get('type') === 'GUEST' ?
                        <React.Fragment>
                            <Route path="/home" exact component={() => {
                                return (<GuestHome/>)
                            }}/>
                            <Route path="/account" exact component={() => {
                                return (<GuestSettings/>)
                            }}/>

                            <Redirect to={"/home"}/>
                        </React.Fragment> : null
                    }
                    {user.get('type') === 'MEMBER' ?
                        <React.Fragment>
                            <Route path="/home" exact component={() => {
                                return (<MemberHome/>)
                            }}/>
                            <Route path="/devices" exact component={() => {
                                return (<DeviceMng/>)
                            }}/>
                            <Route path="/account" exact component={() => {
                                return (<GuestSettings/>)
                            }}/>

                            <Redirect to={"/home"}/>
                        </React.Fragment> : null
                    }


                </Layout>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user'),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        register: () => dispatch(register()),
        getContacts: (...args) => dispatch(getContacts(...args)),
        getCalls: (...args) => dispatch(getCalls(...args)),
        getPendingCall: (...args) => dispatch(getPendingCall(...args)),
        getContainer: (...args) => dispatch(getContainer(...args)),
        getGroups: (...args) => dispatch(getGroups(...args)),
        getDevices: (...args) => dispatch(getDevices(...args)),
        getUsers: (...args) => dispatch(getUsers(...args)),
        // subscribeCallRequest: (...args) => dispatch(subscribeCallRequest(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AxiosManager(App, axios_inst)));
