export const socketOn = (event, handle=null, responseType=null ) => {
    return {
        type: 'SOCKET_SUBSCRIPTION',
        socket:{
            subscribe:{
                event: event,
                handle: handle,
                responseType: responseType
            }
        },
    }
};

export const socketUnsubscribe = (event ) => {
    return {
        type: 'SOCKET_UNSUBSCRIPTION',
        socket:{
            unsubscribe:{
                event: event
            }
        },
    }
};

export const socketEmit = (event, data, handle=null) => {
    return {
        type: 'SOCKET_EMIT',
        socket:{
            emit:{
                event: event,
                handle: handle,
            }
        },
        data:data
    }
};

