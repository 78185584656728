import React, {Component} from "react";
import './SignUpPage.css'
import {fromJS} from 'immutable'
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {CircularProgress, Paper, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import logo from '../library/Icon/logo.png'
import {getSignupData, signUp} from "../../store/actions/data";
import * as _ from 'lodash'
import {enqueueSnackbar} from "../../store/actions/notification";

class SignUpPage extends Component {

    state = {
        token: '',
        userData: null,
    }


    componentDidMount() {
        const queryParam = window.location.search;
        let params = new URLSearchParams(queryParam);
        if (params.has('code')) {
            this.setState({token: params.get('code')})
            this.props.getSignupData(params.get('code'))
                .then((res) => {
                    console.debug(res)
                    console.debug(_.get(res, ['state']))
                    if (_.get(res, 'state') !== 1) {
                        window.location = '/'
                    } else {
                        this.setState({userData: fromJS(res)})
                    }
                })
        } else {
            window.location = '/'
        }
    }

    onUserPropsHandler = (key, value) => {
        this.setState((oldState) => ({
            userData: oldState.userData.updateIn(['userData', key], () => value)
        }))
    }

    onLoginHandler = (event) => {
        event.preventDefault();

        if (this.state.userData.getIn(['userData', 'password'], '').length < 8){
            this.props.enqueueSnackbar({
                message: 'Password troppo corta',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    autoHideDuration: 2000,
                },
            })
            return
        }
        this.props.signup({
            "assignedGroup": this.state.userData.getIn(['userData', 'assignedGroup'], ''),
            "email": this.state.userData.getIn(['userData', 'email'], ''),
            "name": this.state.userData.getIn(['userData', 'name'], ''),
            "password": this.state.userData.getIn(['userData', 'password'], ''),
            "relatedContainer": this.state.userData.getIn(['userData', 'relatedContainer'], ''),
            "surname": this.state.userData.getIn(['userData', 'surname'], ''),
            "token": this.state.token
        }).then(() => window.location = '/')

    }

    render() {
        const {t} = this.props;

        return (
            // <Router basename="/">
            //     <Route path={'/'} component={
            <div>
                {
                    this.state.userData &&
                    <div className={'signup'}>
                        <Paper variant={"elevation"} className={'container'}>
                            <img src={logo} alt="" className={'logo'}/>
                            {/*<div className={'logo'}/>*/}
                            <form className={'form'} onSubmit={this.onLoginHandler}>
                                <TextField
                                    color={"primary"}
                                    label={t('Nome')}
                                    type={'name'}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    onChange={(e) => this.onUserPropsHandler('name', e.target.value)}
                                    value={this.state.userData.getIn(['userData', 'name'], '')}
                                />
                                <TextField
                                    color={"primary"}
                                    label={t('Cognome')}
                                    type={'surname'}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    onChange={(e) => this.onUserPropsHandler('surname', e.target.value)}
                                    value={this.state.userData.getIn(['userData', 'surname'], '')}
                                />
                                <TextField
                                    color={"primary"}
                                    label={t('Email')}
                                    type={'email'}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    onChange={(e) => this.onUserPropsHandler('email', e.target.value)}
                                    value={this.state.userData.getIn(['userData', 'email'], '')}
                                    disabled={true}
                                    required
                                />
                                <TextField
                                    type={'password'}
                                    color={"primary"}
                                    label={t('Password')}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    onChange={(e) => this.onUserPropsHandler('password', e.target.value)}
                                    value={this.state.userData.getIn(['userData', 'password'], '')}
                                    error={this.state.userData.getIn(['userData', 'password'], '').length < 8 &&
                                    this.state.userData.getIn(['userData', 'password'], '').length > 0}
                                    helperText={t('Lunghezza minima 8 caratteri')}
                                    required
                                />
                                <Button
                                    className={'confirm'}
                                    variant={"contained"}
                                    onClick={this.onLoginHandler}
                                    type={"submit"}
                                >
                                    {this.props.loading ? <CircularProgress
                                        style={{
                                            height: '24px',
                                            width: '24px',
                                            margin: '0 10px 0 0 '
                                        }}/> : null}
                                    {t(`Registrati`)}
                                </Button>
                            </form>
                        </Paper>
                    </div>
                }

            </div>
            //     }/>
            // </Router>
        )
    }
}


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        getSignupData: (...args) => dispatch(getSignupData(...args)),
        signup: (...args) => dispatch(signUp(...args)),
        enqueueSnackbar: (...args) => dispatch(enqueueSnackbar(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignUpPage));
