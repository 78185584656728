import * as actionType from '../actions/actions'
import {List, Map} from "immutable";
import {updateObject} from "../utility";

const initialState = {
    calls: List([]),
    contacts: List([]),
    devices: List([]),
    users: List([]),
    groups: List([]),
    container: Map({}),
    groupStatistic: Map({}),
    userStatistic: Map({}),
}

const data = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_CALLS:
            return updateObject(state, {calls: action.data});
        case actionType.UPDATE_CONTACTS:
            return updateObject(state, {contacts: action.data});
        case actionType.UPDATE_DEVICES:
            return updateObject(state, {devices: action.data});
        case actionType.UPDATE_USERS:
            return updateObject(state, {users: action.data});
        case actionType.UPDATE_CONTAINER:
            return updateObject(state, {container: action.data});
        case actionType.UPDATE_GROUPS:
            return updateObject(state, {groups: action.data});
        case actionType.UPDATE_GROUP_STATISTIC:
            return updateObject(state, {groupStatistic: action.data});
        case actionType.UPDATE_USER_STATISTIC:
            return updateObject(state, {userStatistic: action.data});
        case actionType.RESET_DATA:
            return updateObject(state, {
                calls: List([]),
                contacts: List([]),
                devices: List([]),
                users: List([]),
                groups: List([]),
                container: Map({}),
            });

        default:
            return state

    }
    // return state
};

export default data;