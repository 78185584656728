import React, {PureComponent} from "react";
import './GuestHome.css'
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getCalls, getContacts, getContainer, getUserStatistic} from "../../store/actions/data";
import RecentCall from "../library/RecentCall/RecentCalls";
import Contact from "../library/Contact/Contact";
import Statistic from "../library/Statistic/Statistic";
import RefreshButton from "../library/IconTpButton/IconTpButton";
import LoopIcon from "@material-ui/icons/Loop";
import {sendCallRequest} from "../../store/actions/calls";
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import HourglassFullRoundedIcon from '@material-ui/icons/HourglassFullRounded';

class GuestHome extends PureComponent {


    componentDidMount() {
        this.props.getCalls();
        this.props.getContacts();
        this.props.getContainer();
        this.props.getUserStatistic();
    }

    refreshData = () => {
        this.props.getCalls();
        this.props.getContacts();
        this.props.getContainer();
        this.props.getUserStatistic();
    }


    render() {

        const {t} = this.props

        const dataCall = [
            {
                name: 'Anno corrente',
                value: (this.props.userStatistic.getIn(['currentYearCalls']))
            },
            {
                name: 'Totali',
                value: (this.props.userStatistic.getIn(['totalCalls']))
            },
        ]
        const dataTime = [
            {
                name: 'Anno corrente',
                value: (Math.round(this.props.userStatistic.getIn(['totalDuration'])/3600)).toString()
            },
            {
                name: 'Totale',
                value: (Math.round(this.props.userStatistic.getIn(['currentYearDuration'])/3600)).toString()
            },
        ]


        let recent_calls = null
        if (this.props.calls) {
            recent_calls = this.props.calls.filter(c => {
                return c.get('state') === 5 || c.get('state') < 0
            }).sortBy(c => c.get('tsStart') ?? c.get('tsInsert')).reverse().map((c) => {
                    return (<RecentCall
                        key={c.get('id')}
                        call={c}
                        user={this.props.user.get('user')}
                        call_request={(...args) => this.props.sendCallRequest(...args)}

                    />)
                }
            )
        }

        let contacts = null
        if (this.props.contacts && this.props.container) {
            contacts = this.props.contacts
                .filter(c => c.get('type') === 'MEMBER')
                // .sortBy(c => c.name)
                // .sortBy(c => !c._isOnline)
                .sort((a, b) => {
                    if (a.get('name') > b.get('name')) {
                        if (a.get('isOnline')) {
                            return -1;
                        }
                        if (!a.get('isOnline')) {
                            return 1;
                        }
                    } else if (a.get('name') < b.get('name')) {
                        if (a.get('isOnline')) {
                            return -1;
                        }
                        if (!a.get('isOnline')) {
                            return -1;
                        }
                    } else if (a.get('name') === b.get('name')) {
                        if (a.get('isOnline')) {
                            return 0;
                        }
                        if (!a.get('isOnline')) {
                            return 0;
                        }
                    }

                    return -1;

                })
                .map((c) => {
                        return (<Contact
                            key={c.get('id')}
                            contact={c}
                            container={this.props.container}
                            call_request={(...args) => this.props.sendCallRequest(...args)}
                            actCallRequest={this.props.call_request}
                            // user={this.props.user.get('user')}
                        />)
                    }
                )
        }

        return (
            <div className={'guest_home'}>

                <div className={'header_bar'}>
                    <Typography variant={'h4'}>Home</Typography>
                    <RefreshButton onClick={this.refreshData} icon={LoopIcon} tooltip={t('Ricarica')}/>
                </div>
                <div className={'guest_home_container'}>
                    <div className={'cont_recent_call'}>
                        <div className={'recent_calls'}>
                            {recent_calls}
                        </div>
                    </div>
                    <div className={'right'}>
                        <div className={'statistics'}>
                            <Statistic
                                key={'callTot'}
                                color={'#d32f2f'}
                                title={'Chiamate totali del mese'}
                                icon={AddIcCallIcon}
                                value={this.props.userStatistic.getIn(['currentMonthCalls']) }
                                extra={dataCall}
                                extraHeight={'180px'}

                            />
                            <Statistic
                                key={'minuteTot'}
                                color={'#283593'}
                                title={'Tempo totale passato in chiamata'}
                                icon={HourglassFullRoundedIcon}
                                value={Math.round(this.props.userStatistic.getIn(['currentMonthDuration'])/3600).toString() + ' h'}
                                extra={dataTime}
                                extraHeight={'180px'}
                            />
                            {/*<Statistic*/}
                            {/*    key={'callTot'}*/}
                            {/*    color={'#d32f2f'}*/}
                            {/*    title={'Chiamate totali'}*/}
                            {/*    icon={AddIcCallIcon}*/}
                            {/*    value={this.props.groupStatistic.getIn(['lostCalls'])}*/}
                            {/*/>*/}
                            {/*<Statistic/>*/}
                            {/*<Statistic/>*/}
                            {/*<Statistic/>*/}

                        </div>
                        <div className={'contacts'}>
                            <div className={'inner'}>
                                {contacts}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }


}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        calls: state.data.calls,
        contacts: state.data.contacts,
        container: state.data.container,
        userStatistic: state.data.userStatistic,
        call_request: state.calls.call_request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCalls: () => dispatch(getCalls()),
        getContacts: () => dispatch(getContacts()),
        getContainer: () => dispatch(getContainer()),
        sendCallRequest: (...args) => dispatch(sendCallRequest(...args)),
        getUserStatistic: (...args) => dispatch(getUserStatistic(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GuestHome));
