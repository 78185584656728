import React from "react";
import call_state from "../utility/CallState";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import './RecentCalls.css'
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import HourglassFullRoundedIcon from '@material-ui/icons/HourglassFullRounded';
import CallIcon from '@material-ui/icons/Call';
import Fab from "@material-ui/core/Fab";
import 'moment/locale/it'

const RecentCall = (props) => {

    moment.locale('it')

    const sended_call = props.call.get('contactFrom') === props.user.get('id')
    const other_people = props.call.get('contactFrom') === props.user.get('id')? props.call.get('contactToFullName'): props.call.get('contactFromFullName')
    const date_start = moment(props.call.get('tsStart') ?? props.call.get('tsInsert'), 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
    const date_end = moment(props.call.get('tsEnd'), 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
    // const duration = moment.duration( props.call.get('duration', 0)).humanize()
    const duration = moment.utc(date_end.diff(date_start)).format("HH:mm:ss")

    return (
        <Card className={'recent_call'}>
            <div className={'title'}>
                {call_state(props.call.get('state'), 'icon_state')}
                <Avatar className={'avatar'} src={null}
                        // alt={sended_call ? props.call.get('contactToFullName', ' ').substring(0, 1) : props.call.get('contactFromFullName', ' ').substring(0, 1)}>
                        alt={other_people.substring(0, 1)}>
                    {/*<img src="" alt={sended_call? props.call.get('contactToFullName').substring(0,1): props.call.get('contactFromFullName').substring(0,1)}/>*/}
                </Avatar>

            </div>
            <div className={'recent_call_body'}>
                <Typography variant={"h5"} style={{position:'relative', left:'30px'}}>{sended_call ? props.call.get('contactToFullName') : props.call.get('contactFromFullName')}</Typography>
                <div className={'inline'}>
                    <WatchLaterRoundedIcon style={{margin:'0 3px 0 0', color:'#ADB0BB', width:'20px', height:'20px'}}/>
                    <Typography variant={"subtitle2"} style={{color:'#ADB0BB', }}>{date_start.format("YYYY-MM-DD HH:mm")}</Typography>
                    <HourglassFullRoundedIcon style={{margin:'0 3px 0 10px', color:'#ADB0BB', width:'20px', height:'20px'}}/>
                    <Typography variant={"subtitle2"} style={{color:'#ADB0BB', }}>+{duration}</Typography>
                </div>
                <div className={'inline'}>
                </div>
                {
                    props.user.get('type')  === 'GUEST' && <Fab
                        size="small"
                        color="secondary"
                        aria-label="add"
                        className={'call'}
                        onClick={()=>props.call_request(sended_call? props.call.get('contactTo'): props.call.get('contactFrom'))}
                    >
                        <CallIcon />
                    </Fab>
                }

            </div>


        </Card>

    )


}


export default RecentCall