import React, {Component} from 'react';
import {List} from "immutable";
import {connect} from "react-redux";

const AxiosManager = (WrappedComponent, axios) => {

    const mapStateToProps = state => {
        return {
            token: state.auth.user.get('token'),
        };
    };

    const mapDispatchToProps = dispatch => {
        return {}
    };

    class Jwt extends Component {

        state = {
            errors: List([]),
        };

        constructor(props) {
            super(props);
            this.reqInterceptor = axios.interceptors.request.use(req => {
                // req.headers['Content-Type'] = 'application/json'
                req.headers['Authorization'] = 'Bearer ' + props.token
                return req
            })

            this.resInterceptor = axios.interceptors.response.use(res => {
                return res
            }, error => {
                return Promise.reject(error)
            })
        }

        // componentWillMount() {

        // }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor)
            axios.interceptors.response.eject(this.resInterceptor)
        }

        render() {
            return (
                <React.Fragment>
                    <WrappedComponent {...this.props}/>
                </React.Fragment>
            )
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(Jwt);
}

export default AxiosManager;
