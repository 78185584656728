import * as actionType from '../actions/actions'
import {Map} from "immutable";
import {updateObject} from "../utility";

const initialState = {
    user: Map({}),
    socketConnected: false,
    session: '',
    error: null,
    loading: null
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_USER:
            return updateObject(state, {user: action.user});
        case actionType.SOCKET_CONNECTED:
            return updateObject(state, {socketConnected: action.data});
        case actionType.UPDATE_AM:
            return updateObject(state, {am: action.data});
        case actionType.AUTH_START:
            return updateObject(state, {
                error: null,
                loading: true,
            });
        case actionType.AUTH_SUCCESS:
            return updateObject(state, {
                user: action.user,
                error: null,
                loading: false
            });
        case actionType.AUTH_FAIL:
            return updateObject(state, {
                error: action.error,
                loading: false,
                user: Map({}),
            });
        case actionType.AUTH_LOGOUT:
            return updateObject(state, {
                user: Map({}),
                socketConnected: false,
                session: '',
                error: null,
                loading: null
            });
        case actionType.UPDATE_SESSIONS:
            return updateObject(state, {session: action.ses});
        default:
            return state

    }
    // return state
};

export default auth;