import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import './Layout.css'
import Avatar from "@material-ui/core/Avatar";
import {Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {withRouter} from "react-router-dom"
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import {List, Map} from "immutable";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {disconnect} from "../store/actions/auth";

class Layout extends PureComponent {


    render() {
        const {t} = this.props

        const onChangePageHandler = (destination) => {
            this.props.history.push(destination)
        }

        const actContainer = this.props.container;
        const actGroup = this.props.container.getIn(['groups', this.props.container.get('groups', List([])).findIndex(val => val.get('id') === this.props.user.getIn(['user', 'assignedGroup']))], Map({}))

        return (
            <div className={'main'}>
                <div className={'layout'} variant={"outlined"}>
                    <div className={'user'}>
                        <Avatar className={'avatar'}>
                            {this.props.user.getIn(['user', 'name'], '').substring(0, 1).toUpperCase()}
                        </Avatar>
                        <Typography className={'name'} variant={'h5'}>
                            {this.props.user.getIn(['user', 'name'], '') + ' ' + this.props.user.getIn(['user', 'surname'], '')}
                        </Typography>
                        <Typography className={'container'} variant={'h6'}>
                            {actContainer.get('title')}
                        </Typography>
                        <Typography className={'group'} variant={'subtitle1'}>
                            {actGroup.get('title')}
                        </Typography>
                    </div>
                    <Divider style={{height: '1px', margin: '0 0 20px 0', border: '2px solid #df221d'}} flexItem orientation={"horizontal"}
                             variant={"fullWidth"}/>
                    {
                        this.props.user.get('type') === 'ADMIN' ?
                            <React.Fragment>
                                <Button
                                    variant={"text"}
                                    className={'button'}
                                    size={"large"}
                                    onClick={() => onChangePageHandler('/devices')}
                                >
                                    <DevicesOtherIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                    {t('Gestione device')}
                                </Button>
                                <Button
                                    variant={"text"}
                                    className={'button'}
                                    size={"large"}
                                    onClick={() => onChangePageHandler('/users')}
                                >
                                    <GroupAddIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                    {t('Gestione utenti')}
                                </Button>
                                <Button
                                    variant={"text"}
                                    className={'button'}
                                    size={"large"}
                                    onClick={() => onChangePageHandler('/groups')}
                                >
                                    <GroupAddIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                    {t('Gestione gruppi')}
                                </Button>
                                <Button
                                    variant={"text"}
                                    className={'button'}
                                    size={"large"}
                                    onClick={() => onChangePageHandler('/account')}
                                >
                                    <AccountCircleIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                    {t('Impostazioni')}
                                </Button>
                            </React.Fragment>
                            : null }
                    { this.props.user.get('type') === 'GUEST' ?

                        <React.Fragment>
                            <Button
                                variant={"text"}
                                className={'button'}
                                size={"large"}
                                onClick={() => onChangePageHandler('/home')}
                            >
                                <HomeRoundedIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                {t('Home')}
                            </Button>
                            <Button
                                variant={"text"}
                                className={'button'}
                                size={"large"}
                                onClick={() => onChangePageHandler('/account')}
                            >
                                <AccountCircleIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                {t('Impostazioni')}
                            </Button>
                        </React.Fragment> : null}
                    { this.props.user.get('type') === 'MEMBER' ?

                        <React.Fragment>
                            <Button
                                variant={"text"}
                                className={'button'}
                                size={"large"}
                                onClick={() => onChangePageHandler('/home')}
                            >
                                <HomeRoundedIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                {t('Home')}
                            </Button>
                            <Button
                                variant={"text"}
                                className={'button'}
                                size={"large"}
                                onClick={() => onChangePageHandler('/devices')}
                            >
                                <DevicesOtherIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                {t('Gestione device')}
                            </Button>
                            <Button
                                variant={"text"}
                                className={'button'}
                                size={"large"}
                                onClick={() => onChangePageHandler('/account')}
                            >
                                <AccountCircleIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                                {t('Impostazioni')}
                            </Button>
                            </React.Fragment> : null}
                    <Button
                        variant={"text"}
                        className={'logout_button'}
                        size={"large"}
                        onClick={() => this.props.disconnect()}
                    >
                        <ExitToAppIcon style={{width: '20px', height: '20px', margin: '0 7px 0 0 '}}/>
                        {t('Esci')}
                    </Button>

                </div>
                <div className={'main_content'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        container: state.data.container
    };
};

const mapDispatchToProps = dispatch => {
    return {
        disconnect: () => dispatch(disconnect())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(Layout)));
