import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from "@material-ui/core/SvgIcon";

let StreaMaze = (props) => (
    <SvgIcon {...props} viewBox="25.3244 52.160728 307.75988 295.26243" >
        <path d="m 25.3244,309.2255 v -38.56247 h 17.38947 17.389469 v 11.34095 11.34096 h -5.685836 -5.685806 l 0.204315,15.6883 0.204317,15.68834 28.163367,0.19832 28.163364,0.1983 v -17.39866 -17.39864 h -11.34095 -11.340952 v -43.85171 -43.85166 h 11.340952 11.34095 v 32.51071 32.51075 h 24.20851 24.20846 l -0.20346,-16.82241 -0.20345,-16.82242 -6.61554,-0.2183 -6.61554,-0.21861 v -34.00122 -34.00116 l 33.83383,0.1969 33.83385,0.19689 0.19688,33.81872 0.19689,33.8188 -16.07413,0.20404 -16.07411,0.20403 -0.20345,16.82241 -0.20346,16.82242 h 33.28123 33.28127 v -17.03164 -17.03164 l 11.15192,0.20916 11.15195,0.20917 0.19831,28.15933 0.19831,28.15936 -68.2439,0.19316 -68.24394,0.19317 v 17.01142 17.01145 h 22.6819 22.6819 l 0.2209,-5.8595 0.22088,-5.85949 h 11.32336 11.32334 l -0.20318,17.20045 -0.20316,17.20044 -85.62422,0.1923 -85.624181,0.19232 z m 216.99023,21.17334 v -17.39623 h 11.34095 11.34095 v 6.06294 6.06297 l 16.82242,-0.20345 16.82242,-0.20346 0.1926,-73.9052 0.19289,-73.9052 7.36787,0.003 7.36788,0.003 10.37482,0.0907 8.838,0.0907 0.0668,12.02608 v 11.22428 h -6.04477 -6.04475 l -0.19289,73.52717 -0.19288,73.52719 -39.12625,0.19573 -39.12631,0.19576 v -17.39628 z m -216.99023,-191.66886 v -86.569252 h 76.74045 76.74044 v 17.011415 17.011414 h -11.34095 -11.34095 v -5.670461 -5.670464 h -13.98716 -13.98717 v 17.011415 17.011413 h 68.42373 68.42374 v 39.69336 39.69331 h -11.34095 -11.34095 v -28.35236 -28.35241 h -68.42374 -68.42374 v 12.47506 12.47503 h -11.340954 -11.340953 v -23.81598 -23.81601 h 11.340953 11.340954 v -17.011413 -17.011415 h -28.356683 -28.356681 l 0.193457,63.698328 0.193172,63.69833 5.481462,0.22203 5.481462,0.22205 v 11.30783 11.30784 h -17.389469 -17.38947 z m 273.695,-35.15695 v -28.730398 h -26.08421 -26.08418 v 5.670464 5.670461 h -11.34095 -11.34095 v -17.011414 -17.011415 h 48.38806 48.38808 v 28.730394 28.730418 h 6.04848 6.04852 v 10.84671 l -0.13495,11.85935 -8.92143,-0.0458 -9.32648,0.0229 h -7.81998 -7.81994 z"/>
    </SvgIcon>
);
StreaMaze = pure(StreaMaze);
StreaMaze.displayName = 'ActionHome';
StreaMaze.muiName = 'SvgIcon';

export default StreaMaze;