import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),

        },
    },
}));

const Loading = () => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{alignContent:"center", alignItems:"center", justifyContent:"center", height:'100%', width:'100%'}}>
            <CssBaseline/>
            <CircularProgress style={{}}/>
        </div>
    );
}

export default Loading;