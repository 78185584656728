import React from "react";
import {Dialog, List as ListComponent} from "@material-ui/core";
import './PopUpMemberCall.css'
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {List, Map} from "immutable";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MenuItem from "@material-ui/core/MenuItem";
import {withTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import './CallIcon.scss'
import ExternalCamera from "../Icon/ExternalCamera";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {acceptCallRequest, declineCallRequest} from "../../../store/actions/calls";

class PopUpMemberCall extends React.Component {
    state = {
        othDevices: List([]),
        mainDevices:  '' //localStorage.getItem('lastMainDevice') ||
    }

    componentDidMount() {
        // console.log(this.props.contacts.find(c => c.get('id') === localStorage.getItem('lastMainDevice'), Map({})))
        // if (this.props.contacts.size > 0 && !this.props.contacts.find(c => c.get('id') === localStorage.getItem('lastMainDevice'), Map({})).get('onLine')){
        //     this.setState({mainDevices: ''})
        // }
    }

    callAccept = (e) => {
        e.preventDefault();
        this.props.acceptCallRequest(this.state.mainDevices, this.state.othDevices.toJS())
        localStorage.setItem('lastMainDevice', this.state.mainDevices);
    }

    onExitedPopUp = () => {
        this.setState({othDevices: List([]), mainDevices:  ''}) //localStorage.getItem('lastMainDevice') ||
    }

    callDecline = () => {
        this.props.declineCallRequest()
    }

    onDeviceSelected = (event) => {
        const name = event.currentTarget.getAttribute('name')
        this.setState(prevState => {
            if (!prevState.othDevices.includes(name)) {
                return {othDevices: prevState.othDevices.push(name)}
            } else {
                return {othDevices: prevState.othDevices.filter(val => val !== name)}
            }
        })
    }

    onMainDeviceSelected = (value) => {
        this.setState({mainDevices:value})
    }

    render() {
        const {t} = this.props


        let FromContact = Map({})
        let FromContactGroup = Map({})
        if (this.props.call_request && this.props.call_request.has('id') && this.props.container && this.props.container.get('id')) {
            if (this.props.container.get('groups')) {
                FromContact = this.props.contacts.find(c => c.get('id') === this.props.call_request.get('contactFrom'))
                FromContactGroup = this.props.container.getIn(['groups', this.props.container.get('groups').findIndex(val => val.get('id') === FromContact.get('relatedContainer'))])
            }
        }

        let OtherDevices = null
        if (this.props.contacts && this.props.container) {
            let StreaMaze = this.props.contacts.filter(d => d.get('type') === 'SA')
            if (StreaMaze.size > 0) {
                StreaMaze = StreaMaze.get(0)

                OtherDevices = this.props.contacts
                    .filter(d => d.get('type') !== 'MEMBER' && d.get('type') !== 'GUEST' && d.get('type') !== 'SG' && d.get('type') !== 'SA')
                    .map((d) => {
                            let device_icon = null
                            if (d.get('type') === 'EC') {
                                device_icon = <ExternalCamera
                                    style={{
                                        color: StreaMaze.get('isOnline') ? '#FFF' : '#848484',
                                        height: '30px',
                                        width: '30px'
                                    }}
                                    disabled={!StreaMaze.get('isOnline')}/>
                            } else if (d.get('type') === 'SP') {
                                device_icon = <PhoneAndroidIcon
                                    style={{
                                        color: StreaMaze.get('isOnline') ? '#FFF' : '#848484',
                                        height: '30px',
                                        width: '30px'
                                    }}
                                    disabled={!StreaMaze.get('isOnline')}/>
                            } else {
                                device_icon = <HelpOutlineIcon
                                    style={{
                                        color: StreaMaze.get('isOnline') ? '#FFF' : '#ADB0BB',
                                        height: '30px',
                                        width: '30px'
                                    }}
                                    disabled={!StreaMaze.get('isOnline')}/>
                            }

                            return (
                                <ListItem key={d.get('id')}
                                          role={undefined}
                                          dense
                                          button
                                          disabled={!StreaMaze.get('isOnline')}
                                          name={d.get('id')}
                                          onClick={(event) => this.onDeviceSelected(event)}
                                          ContainerProps={{name: d.get('id')}}
                                          style={{zIndex: 3000}}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            name={d.get('id')}
                                            edge="start"
                                            checked={this.state.othDevices.includes(d.get('id'))}
                                            tabIndex={-1}
                                            disableRipple
                                            // onChange={(event) => onDeviceSelected(event)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={d.get('id')} primary={d.get('name')}/>
                                    <ListItemSecondaryAction>
                                        {/*<IconTpButton edge="end" aria-label="comments" disabled={!StreaMaze._isOnline}>*/}
                                        {device_icon}
                                        {/*</IconTpButton>*/}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }
                    )
            }

        }

        let MainDevices = null
        if (this.props.contacts && this.props.container) {
            MainDevices = this.props.contacts
                .filter(d => d.get('type') === 'SG')
                .map((d) => {
                        return (
                            <MenuItem key={d.get('id')} value={d.get('id')}
                                      disabled={!d.get('isOnline') || d.get('isBusy')}>
                                {d.get('name') + (d.get('isOnline') ? '' : ' - ' + t('OFFLINE')) + (d.get('isBusy') ? ' - ' + t('OCCUPATO') : '')}
                            </MenuItem>
                        )
                    }
                )
        }

        if (this.props.call_request && this.props.container && FromContactGroup) {
            return (
                <Dialog open={this.props.call_request.get('state') === 1}
                        className={'PopUpMemberCall'} maxWidth={"md"}
                        onExited={this.onExitedPopUp}>
                    <form>
                        <div className={'PopUpTitle'}>
                            <i className={'callIcon is-animating'}>
                                {/*<CallIcon className={'subCallIcon '} style={{  height: '30px',*/}
                                {/*    width: '30px'}}/>*/}
                            </i>
                            <Typography variant={"h3"}
                                        style={{
                                            fontSize: '30px',
                                            margin: '0 0 0 20px'
                                        }}>{t('Chiamata in arrivo')}</Typography>
                        </div>
                        <div className={'PopUpBody'}>
                            <div className={'PopUpHeader'}>
                                <Avatar className={'PopUpAvatar'}/>
                                <Typography variant={"h4"}
                                            className={'popUpContactName'}>{FromContact.get('name', t('Nome non valido'))}</Typography>
                                <Typography variant={"subtitle2"}
                                            className={'contGroupInfo'}>{this.props.container.get('title')} - {FromContactGroup.get('title')}</Typography>
                                {/*<Typography variant={"subtitle2"}*/}
                                {/*            className={'callDescription'}>{props.call_request.description}</Typography>*/}
                            </div>
                            <div className={'PopUpContent'}>
                                <TextField
                                    id="standard-select-currency"
                                    select
                                    label={t('Device principale')}
                                    value={this.mainDevices}
                                    onChange={(event) => this.onMainDeviceSelected(event.target.value)}
                                    // helperText={t('Seleziona un device principale')}
                                    className={'mainDevice'}
                                    variant={"outlined"}
                                    required={true}
                                >
                                    {MainDevices}
                                </TextField>
                                <Typography>{t('Device secondari')}</Typography>
                                <ListComponent className={'othDevicesList'}>
                                    {OtherDevices}
                                </ListComponent>
                                <Typography variant={"body2"}
                                            style={{color: '#ADB0BB'}}>{t('selezione multipla')}</Typography>
                                <div className={'popUpAction'}>
                                    <Button
                                        variant={"outlined"}
                                        style={{flex: 1, margin: '0 10px 0 0'}}
                                        onClick={this.callDecline}
                                    >
                                        {t('Rifiuta')}
                                    </Button>
                                    <Button
                                        style={{flex: 1}}
                                        variant={"contained"}
                                        onClick={(e) => this.callAccept(e)}
                                        type={"submit"}
                                    >
                                        {t('Accetta')}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {/*{props.call_request === null ? null :*/}
                        {/*    <div style={{display: 'flex', flexDirection: 'column'}}>*/}
                        {/*        {props.call_request.contactTo === props.session.contact.id ?*/}
                        {/*            <div>*/}
                        {/*                <button onClick={callAccept}>ACCEPT</button>*/}
                        {/*                <button onClick={callDecline}>DECLINE</button>*/}
                        {/*            </div> : null}*/}
                        {/*        <span>ID: {props.call_request.id}</span>*/}
                        {/*        <span>contactFrom: {props.call_request.contactFrom}</span>*/}
                        {/*        <span>contactTo: {props.call_request.contactTo}</span>*/}
                        {/*        <span>props: {props.call_request.props}</span>*/}
                        {/*        <button onClick={callHangup}>HANGUP</button>*/}
                        {/*        /!*{props.call_request.subCalls !== null ? props.call_request.subCalls.map(sc => <pre>{sc.id} - {sc.props}</pre>) : null}*!/*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </form>
                </Dialog>
            )
        } else {
            return null
        }

    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user'),
        contacts: state.data.contacts,
        container: state.data.container,
        call_request: state.calls.call_request,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        acceptCallRequest: (...args) => dispatch(acceptCallRequest(...args)),
        declineCallRequest: (...args) => dispatch(declineCallRequest(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopUpMemberCall));