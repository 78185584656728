import React, { useState} from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import './ConfigDevice.css'
import Avatar from "@material-ui/core/Avatar";
import SmartGlasses from "../Icon/SmartGlasses";
import StreaMaze from "../Icon/StreamazeIcon";
import ExternalCamera from "../Icon/ExternalCamera";
import {withTranslation} from "react-i18next";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import {MenuItem, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {deleteDevice, updateDevice} from "../../../store/actions/data";

const ConfigDevice = ({device, t}) => {

    const [dev, setDev] = useState(device)
    const dispatch = useDispatch();
    const _updateDevice = (...args) => dispatch(updateDevice(...args));
    const _deleteDevice = (...args) => dispatch(deleteDevice(...args));
    const groups = useSelector(store => store.data.container.get('groups'));

    /**
     *Stato utente
     * Busy => in chiamata
     * Online => se Login eseguito
     */
    let device_icon
    let device_type
    if (device.get('type') === 'SG') {
        device_icon = <SmartGlasses style={{color: '#FFF', height: '15px', width: '1000px'}}/>
        device_type = 'Smart Glasses' + (device.hasIn(['attributes','model']) ? ' - ' + device.getIn(['attributes','model']) : '')
    } else if (device.get('type') === 'SA') {
        device_icon = <StreaMaze style={{color: '#FFF', height: '20px', width: '50000px'}}/>
        device_type = 'StreaMaze' + (device.hasIn(['attributes','model']) ? ' - ' + device.getIn(['attributes','model']) : '')
    } else if (device.get('type') === 'EC') {
        device_icon = <ExternalCamera style={{color: '#FFF', height: '30px', width: '50000px'}}/>
        device_type = 'Telecamera esterna' + (device.hasIn(['attributes','model']) ? ' - ' + device.getIn(['attributes','model']) : '')
    } else if (device.get('type') === 'SP') {
        device_icon = <PhoneAndroidIcon style={{color: '#FFF', height: '30px', width: '50000px'}}/>
        device_type = 'Telefono' + (device.hasIn(['attributes','model']) ? ' - ' + device.getIn(['attributes','model']) : '')
    } else {
        device_icon = <HelpOutlineIcon style={{color: '#FFF', height: '30px', width: '50000px'}}/>
        device_type = t('Device sconosciuto')
    }

    const editDev = (key, value) => {
        setDev((d) => {return d.updateIn(key, () => value)})
    }

    let groupList = null
    if (groups){
        groupList = groups.sort().map((g) => {
            return <MenuItem key={g.get('id')} value={g.get('id')}>
                        {g.get('title')}
                    </MenuItem>
        })
    }

    const disableDevice = (dev) => {
        dev = dev.update('enable', val => !val)
        _updateDevice(dev).then(res => setDev(res)
        )
    }

    return (
        <Card className={'ConfigDevice'} elevation={8}>
            <div className={'title'}>
                {/*<div className={'state'} style={{borderColor: state_contact}}>*/}
                    <Avatar className={'state'}>
                        {device_icon}
                    </Avatar>
                {/*</div>*/}
                <Typography className={'name'}>{device.has('name') ? device.get('name') : t('Device senza nome')}</Typography>
                <Typography variant={"subtitle2"} className={'device_type info'}>{device_type}</Typography>
                <Typography variant={"subtitle2"} className={'device_id info'}> id: {device.get('id')}</Typography>


            </div>
            <div className={'body_1'}>
                <TextField
                label={t('Nome')}
                variant={"outlined"}
                value={dev.get('name', '')}
                className={'input'}
                onChange={(e) => editDev(['name'], e.target.value)}
                />

                <TextField
                label={t('Gruppo')}
                variant={"outlined"}
                select
                value={dev.get('assignedGroup')}
                className={'input'}
                onChange={(e) => editDev(['assignedGroup'], e.target.value)}
                >
                    {groupList}
                </TextField>
                {device.get('type') === 'EC' ? <TextField
                label={t('Indirizzo rtsp')}
                variant={"outlined"}
                value={dev.getIn(['attributes', 'connection', 'url'])}
                className={'input'}
                disabled={dev.get('type') !== 'EC'}
                onChange={(e) => editDev(['attributes', 'connection', 'url'], e.target.value)}
                /> : null }
                <div
                className={'actions'}
                >
                    <Button
                    variant={"outlined"}
                    onClick={()=>_deleteDevice(dev.get('id'))}
                    >
                        {t('Elimina')}
                    </Button>
                    <Button
                    variant={"contained"}
                    onClick={()=>disableDevice(dev)}
                    style={{backgroundColor:device.get('enable')?'#43a047':'#e53935'}}
                    >
                        {device.get('enable')?t('Disattiva'):t('Attiva')}
                    </Button>
                    <Button
                    variant={"contained"}
                    onClick={()=>_updateDevice(dev)}
                    >
                        {t('Salva')}
                    </Button>

                </div>
            </div>
        </Card>

    )


}


export default withTranslation()(ConfigDevice);