import * as actionType from "./actions";
import axios_inst from "../../axios_inst";
import {fromJS, Map} from "immutable";
import {socketEmit, socketOn} from "./socketIO";
import {closeSnackbar, enqueueSnackbar} from './notification'
import Button from "@material-ui/core/Button";
import CallEndIcon from '@material-ui/icons/CallEnd';
import * as _ from 'lodash'
import contact from "../../components/test/contact";
import {getCalls} from "./data";
// region update function


export const UpdateCallRequest = (data) => {
    return {
        type: actionType.UPDATE_CALL_REQUEST,
        data: data
    }
};
//
// export const UpdateStream = (data) => {
//     return {
//         type: actionType.UPDATE_STREAMS,
//         data: data
//     }
// };

export const UpdateSubcall = (data) => {
    return {
        type: actionType.UPDATE_SUBCALL,
        data: data
    }
};

export const UpdateSubcalls = (data) => {
    return {
        type: actionType.UPDATE_SUBCALLS,
        data: data
    }
};

export const AddLocalTrack = (data) => {
    return {
        type: actionType.ADD_LOCAL_TRACK,
        data: data
    }
};

export const UpdateMessages = (data) => {
    return {
        type: actionType.UPDATE_MESSAGES,
        data: data
    }
};


export const UpdateConfig = (data) => {
    return {
        type: actionType.UPDATE_CONFIG,
        data: data
    }
};


export const UpdateSenderStreamsMuted = (data) => {
    return {
        type: actionType.UPDATE_SENDER_STREAMS_MUTED,
        data: data
    }
};

export const callEvents = (dispatch, data) => {
    dispatch(socketOn(`call/${data.get('id')}/update`, (...args) => dispatch(update_call_request(...args))))
}

// endregion

export const onNewCallIncoming = (callRequest) => {
    return dispatch => {
        const data = fromJS(callRequest)
        dispatch({type: actionType.UPDATE_CALL_REQUEST, data: data})
        callEvents(dispatch, data)

    }
}


// export const subscribeCallRequest = () => {
//     return (dispatch) => {
//
//     }
// }

/**
 * Get Chiamate pendenti
 * @returns
 */
export const getPendingCall = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (getState().calls.call_request && getState().calls.call_request.get('state', 5) > 3) {
                axios_inst.get('/calls/pending')
                    .then(res => {
                        if (Object.keys(res.data.data).length > 0 && res.data.data.constructor === Object) {
                            const data = fromJS(res.data.data)
                            console.info("pending calls", data);
                            callEvents(dispatch, data)
                            dispatch(UpdateCallRequest(data))
                            if (data.get('subCalls')) {
                                data.get('subCalls').forEach(s => {
                                    dispatch(socketOn(`subcall/${s.get('id')}/update`, (...args) => {
                                        dispatch(update_subcalls(s.get('id'), ...args))
                                    }))
                                })
                                dispatch(UpdateSubcalls(data.get('subCalls')))
                            }
                        }
                        return resolve(res.data.data)
                    })
                    .catch(error => reject(error))
            } else {
                reject(false)
            }
        })

    }
}

const update_subcalls = (id, data) => {
    return (dispatch, getState) => {
        const newData = fromJS(data)
        const subcalls = getState().calls.subcalls
        const subcallsId = subcalls.findIndex(item => item.get('id') === id)
        dispatch(UpdateSubcalls(subcalls.updateIn([subcallsId], sc => sc.merge(newData))))
        console.info(`Subcall ${id} aggiornata`)
    }
}


const update_call_request = (data) => {
    return (dispatch, getState) => {
        console.debug('update_call_request', data)
        const new_data = fromJS(data)
        if (new_data.has('subCalls') && new_data.get('state') < 4) {
            new_data.get('subCalls').forEach(s => {
                dispatch(socketOn(`subcall/${s.get('id')}/update`, (...args) => {
                    dispatch(update_subcalls(s.get('id'), ...args))
                }))
            })
            dispatch(UpdateSubcalls(new_data.get('subCalls')))
        }
        dispatch(UpdateCallRequest(getState().calls.call_request.merge(new_data)))
        if (new_data.get('state') === 5) {
            dispatch(HangUpCall())
        }
    }
}


export const callAccept = (data) => {
    return (dispatch, getState) => {
        console.debug('call_accept', data)
        dispatch(UpdateSubcall(fromJS(data)))
        dispatch(UpdateConfig(getState().calls.config.updateIn(['iceServers'], () => fromJS(data.turnCreds))  ))
        dispatch({type: actionType.CREATE_SENDER_PEER, data: {id: data.id}})
            .then(() => dispatch(getLocalStream()))
        dispatch(closeSnackbar(getState().notification.notifications.find(n => n.message === 'Call in corso').key))
    }
}

/**
 * Invio richiesta di chiamata
 */
export const sendCallRequest = (contactId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            console.debug(getState().calls.call_request)
            console.debug(getState().calls.call_request.keySeq().size)
            let callReqState = getState().calls.call_request.get('state')
            if (callReqState >= 1 && callReqState <= 4) {
                dispatch(enqueueSnackbar({
                    message: 'Call già in corso',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 2000,
                    },
                }))
                reject()
                return
            }
            axios_inst.post(`/calls`, {"contactTo": contactId})
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("request call object", data);
                    dispatch(UpdateCallRequest(data))
                    callEvents(dispatch, data)
                    dispatch(enqueueSnackbar({
                        message: 'Call in corso',
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'success',
                            // autoHideDuration: 2000,
                            persist: true,
                            action: (
                                <Button color="primary" size="small" onClick={() => dispatch(HangUpCall())}>
                                    <CallEndIcon/>
                                </Button>
                            )
                        },
                    }))
                    resolve(data);
                })
                .catch(err => {
                    console.error(`/calls`, err)
                    let message = 'Errore interno,\nRiprovare!'
                    if (err.response) {
                        if (err.response.data.code === 412) message = 'Utente non disponibile'
                    } else {
                        message = err.message
                    }
                    dispatch(enqueueSnackbar({
                            message: message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        })
                    )
                    reject(err)
                })
        })
    }
}

/**
 * Invio richiesta di recall
 */
export const recall = (callId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`calls/${callId}/recall`)
                .then(res => {
                    const data = fromJS(res.data.data)
                    console.debug("recall", data);
                    dispatch(UpdateCallRequest(data))
                    resolve(data);
                })
                .catch(error => {
                    console.error(error)
                    reject(error)
                })
        })
    }
}

export const getLocalStream = () => {
    return (dispatch, getState) => {

        navigator.mediaDevices.getUserMedia(getState().calls.streamConstraints)
            .then(stream => dispatch(AddLocalTrack(stream)))
            .then(() => dispatch({type: actionType.MAKE_OFFER}))
            .catch(error => {
                //#TODO ABORT CALL
                console.error("Could not acquire media.", error)
            })


    }
}

/**
 * Chiusura chiamata
 */
export const HangUpCall = () => {
    return (dispatch, getState) => {
        dispatch(closeSnackbar(_.get(getState().notification.notifications.find(n => n.message === 'Call in corso'), 'key')))
        if (getState().calls.call_request) {
            axios_inst.post(`/calls/${getState().calls.call_request.get('id')}/hangup`)
                .then(res => {
                    dispatch(getCalls())
                })
                .catch(error => console.error(error))
            dispatch({type: actionType.HANG_UP})

        }
    }
}


export const subscribe = (subCallId, unsubscribe = false, video = true, audio = true) => {
    return (dispatch, getState) => {
        console.info('send subscribe', subCallId)
        const call_request = getState().calls.call_request
        const contactId = getState().auth.user.get('id');
        const callContactPeer = call_request.get('contactFrom') === contactId ? call_request.get('contactTo') : call_request.get('contactFrom');

        const subcallMainDevice = getState().calls.subcalls.getIn([1, 'contactFrom'])
        const mainSubcall = getState().calls.subcalls.get(getState().calls.subcalls.findIndex(sc => sc.get('id') === getState().calls.subcall))
        dispatch(socketEmit(unsubscribe ? 'unsubscribe' : 'subscribe', {
            callId: getState().calls.call_request.get('id'),
            subCallId: getState().calls.subcall,
            from: mainSubcall.get('contactFrom'),
            to: mainSubcall.get('contactTo'),
            feed: subCallId,
            audio: audio,
            video: video
        }))
        if (!unsubscribe) {
            dispatch(changeSubscription(
                call_request.get('id'),
                getState().calls.subcall,
                mainSubcall.get('contactFrom'),
                [callContactPeer, subcallMainDevice],
                subCallId
                ))
        }
    }
}

export const memberChangeSubscription = (subCallId) => {
    // recupero id member -> contact_to è l'id del guest
    // recupero id main device
    // invio change subscription
    return (dispatch, getState) => {
        const call_request = getState().calls.call_request
        const contactId = getState().auth.user.get('id');
        const callContactPeer = call_request.get('contactFrom') === contactId ? call_request.get('contactTo') : call_request.get('contactFrom');

        dispatch(changeSubscription(
            call_request.get('id'),
            null,
            contactId,
            [callContactPeer, call_request.getIn(['settings', 'mainDevice'])],
            subCallId))
    }
}

const changeSubscription = (callId, subCallId, from, to, feed) => {
    console.log(callId, subCallId, from, to, feed)
    return (dispatch, getState) => {
        const data = {
            callId: callId,
            subCallId: subCallId,
            from: from,
            to: to,
            feed: feed,
        }
        console.log("changeSubscription data", data)
        dispatch(socketEmit('changeSubscription', data))
    }
}

export const handleChangeSubscription = (data) => {
    return (dispatch, getState) => {
        console.info("on change subscription", data)
        if(!getState().calls.call_request) return;
        const cf = getState().calls.feed;
        if(cf === data.feed || data.feed === null) {
            console.log("already subscribed")
            return;
        } //already subscribed
        dispatch({type: actionType.UPDATE_FEED, data: data.feed})
        // if(cf != null) dispatch(subscribe(cf, true))  //unsubscribe old subscription
        // dispatch(subscribe(data.feed, false))
    }
}


export const setSettings = (settings) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.put(`/calls/${getState().calls.call_request.get('id')}/settings`, settings)
                .then(res => {
                    return resolve(true);
                })
                .catch(error => {
                    console.error(error);
                    return reject(error);
                })
        })
    }
}

export const acceptCallRequest = (mainDevice, devices = []) => {
    return (dispatch, getState) => {
        dispatch(setSettings({mainDevice, devices}))
            .then(() => {
                const callRequest = getState().calls.call_request
                axios_inst.post(`/calls/${callRequest.get('id')}/accept`)
                    .then(res => {
                        if (res.data.code === 414) {
                            dispatch(enqueueSnackbar({
                                message: 'Uno o più device offline',
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'warning',
                                    autoHideDuration: 5000,
                                },
                            }))
                        }
                        console.info(`Call ${callRequest.get('id')} accepted`, res.data.data);
                        dispatch(UpdateCallRequest(callRequest.merge(fromJS(res.data.data))))
                    })
                    .catch(err => {
                        console.error(`/acceptCallRequest`, err)
                        let message = 'Errore interno,\nRiprovare!'
                        if (err.response) {
                            if (err.response.data.code === 400) {
                                dispatch(HangUpCall())
                                message = 'Errore'
                            }
                            if (err.response.data.code === 406) message = 'Main Device non selezionato'
                            if (err.response.data.code === 404) {
                                dispatch({type: actionType.HANG_UP})
                                message = 'Call non non valida'
                            }
                            if (err.response.data.code === 410) message = 'Main Device non disponibile'
                            if (err.response.data.code === 411) message = 'StreaMaze non disponibile'
                            if (err.response.data.code === 412) message = 'StreaMaze offline'

                        } else {
                            message = err.message
                        }
                        dispatch(enqueueSnackbar({
                            message: message,
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 3000,
                            },
                        }))
                    })
            })

    }
}

export const declineCallRequest = () => {
    return (dispatch, getState) => {
        const callRequest = getState().calls.call_request
        axios_inst.post(`/calls/${callRequest.get('id')}/decline`)
            .then(res => {
                console.info(`Call ${callRequest.get('id')} accepted`, res.data.data);
                dispatch(UpdateCallRequest(Map({})))
            })
            .catch(error => {
                console.error(error);
            })
    }
}

export const muteMic = () => {
    return (dispatch, getState) => {
        console.debug('Mute mic')
        getState().calls.senderStreams.getAudioTracks().forEach(at => at.enabled = !at.enabled)

        const muteState = getState().calls.senderStreams.getAudioTracks().some(c => c.muted)
        dispatch(UpdateSenderStreamsMuted(!getState().calls.senderStreamsMuted))
    }
}

export const chatSubscribe = (action) => {
    return dispatch => {
        dispatch(socketOn(`chat/on`, (...args) => onNewMessage(...args)))
    }
}

export const onNewMessage = (newMessage) => {
    return (dispatch, getState) => {
        console.info('newMessage', newMessage)
        dispatch(UpdateMessages(getState().calls.messages.push(fromJS(newMessage))))
    }
}

export const sendMessage = (message) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            console.debug('messag2', message)
            const sended_call = getState().calls.call_request.get('contactFrom') === getState().auth.user.getIn(['user', 'id'])

            dispatch(socketEmit('chat/send', {
                contactFrom: getState().calls.call_request.getIn([sended_call ? 'contactFrom' : 'contactTo']),
                contactTo: getState().calls.call_request.getIn([sended_call ? 'contactTo' : 'contactFrom']),
                callId: getState().calls.call_request.get('id'),
                message: message,
                attributes: {},
                replyId: null
            }, (res) => resolve(dispatch(UpdateMessages(getState().calls.messages.push(fromJS(res)))))))


        })
    }
}

