import React, {Component} from "react";
import './GroupsMng.css'
import {withTranslation} from "react-i18next";
import RefreshButton from "../library/IconTpButton/IconTpButton";
import {Typography} from "@material-ui/core";
import {addGroup, deleteGroup, getContainer, getGroups, getUsers, updateGroup} from "../../store/actions/data";
import {connect} from "react-redux";
import {fromJS, List} from 'immutable'
import LoopIcon from "@material-ui/icons/Loop";
import MaterialTable from "material-table";
import {TableIcons} from "../library/Icon/TableIcons";
import {translation} from "../library/utility/Table";

class GroupsMng extends Component {

    state = {
        actFilter: 'all',
        popUpNewDevice: false,
        newDevice: 1,
        qrcodeCode: null,
        ipAddress: '',
    }

    componentDidMount() {
        this.props.getContainer();
        this.props.getGroups();
    }

    componentWillUnmount() {
        if (this.state.qrcodeCode) {
            this.props.am.abortPairingSmartGlasses(this.state.qrcodeCode).then(() =>
                this.setState({popUpNewDevice: false}))
        }
    }

    refreshData = () => {
        this.props.getContainer();
        this.props.getGroups();

    }

    addDevice = () => {
        this.props.am.abortPairingSmartGlasses(this.state.qrcodeCode).then(() =>
            this.setState({popUpNewDevice: false}))
    }

    onChangeFilter = (value) => {
        this.setState({actFilter: value})
    }

    handleChangeDevice = (e, value) => {
        this.setState({newDevice: value})
    }

    onOpenPopUp = () => {
        this.props.am.addNewSmartGlasses('New device').then((res) =>
            this.setState({qrcodeCode: res, popUpNewDevice: true})
        )
    }

    onAbortAddDevice = () => {
        if (this.state.qrcodeCode) {
            this.props.am.abortPairingSmartGlasses(this.state.qrcodeCode).then(() =>
                console.debug('')
            )
        }
        this.setState({popUpNewDevice: false})
    }

    // onClosePopUp = () => {
    //     this.setState({popUpNewDevice: false})
    // }

    onAddExtCamera = () => {
        this.props.addNewExternalCamera('Nuovo device', this.state.ipAddress).then(() =>
            this.setState({popUpNewDevice: false})
        )
    }

    onInputText = (e) => {
        this.setState({ipAddress: e.target.value})
    }

    render() {
        const {t} = this.props

        // let cnt_list = null
        // if (this.props.container) {
        //     cnt_list = this.props.container.get('groups', List([]))
        //         .sortBy(val => val.get('title'))
        //         .map((group) => {
        //             console.log(group)
        //             return (
        //                 <DeviceGroup
        //                     dev={this.props.devices.filter(dev => dev.get('assignedGroup') === group.get('id'))}
        //                     group={group}
        //                     filter={this.state.actFilter}
        //                 />
        //             )
        //         })
        // }

        let groups = {};
        if (this.props.container.get('groups', List([])).size > 0) {
            // groups = this.props.container.get('groups').toMap()
            this.props.container.get('groups').map((g) => {
                groups[g.get('id')] = g.get('title')
            })
        }

        let action = {
            onRowAdd: newData => new Promise((resolve, reject) => {
                this.props.addGroup(newData)
                resolve()
            }),
            onRowDelete: oldData => new Promise((resolve, reject) => {
                this.props.deleteGroup(oldData.id)
                resolve()
            }),
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                this.props.updateGroup(fromJS(newData))
                resolve()
            }),
        }

        const local = translation(t)

        return (
            <React.Fragment>
                <div className={'groupMng'}>
                    <div className={'header_bar'}>
                        <Typography variant={'h4'}>{t('Gestione gruppi')}</Typography>
                        <div>
                            <RefreshButton onClick={this.refreshData} icon={LoopIcon} tooltip={t('Aggiorna')}/>
                        </div>
                    </div>
                    <div className={'body'}>
                        <MaterialTable
                            icons={TableIcons}
                            columns={[
                                {title: t("Id"), field: "Id", hidden: true},
                                {title: t("Nome"), field: "title", editable: "always"},
                                {title: t("Descrizione"), field: "description", editable: "always"},
                                {title: t("Identificativo licenza"), field: "uuid", editable: "never"},

                            ]}
                            editable={action}
                            // data={this.props.historicalMissions ? this.props.historicalMissions.toJS() : undefined}
                            data={this.props.groups.toJS()}
                            localization={local}

                            style={{
                                // height:'100%'
                                backgroundColor: 'rgba(0,0,0,0)',
                                boxShadow: 'unset',
                            }}
                            // title={t("Getione utenti")}


                            options={{
                                // filtering: true,
                                // search: false,

                                showTitle: false,
                                // toolbar: false,
                                // paginationType: "normal",
                                // maxBodyHeight:document.body.scrollHeight - 80,
                                pageSize: parseInt(((document.body.scrollHeight - 340) / 57).toFixed()),
                                // pageSizeOptions: [],
                                // padding: "dense",
                                // // paging: false,
                                // tableLayout:"fixed",
                                pageSizeOptions: [5, 10, 20],
                                // pageSize: 5,
                                searchFieldStyle: {
                                    minWidth: '300px'
                                },
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        )

    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        groups: state.data.groups,
        container: state.data.container,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getContainer: () => dispatch(getContainer()),
        getGroups: () => dispatch(getGroups()),
        updateGroup: (...args) => dispatch(updateGroup(...args)),
        deleteGroup: (...args) => dispatch(deleteGroup(...args)),
        addGroup: (...args) => dispatch(addGroup(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GroupsMng));
