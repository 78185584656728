import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend';
import yaml from 'js-yaml';

const locale =  window.navigator.userLanguage || window.navigator.language;
if(!localStorage.getItem('language')){
    localStorage.setItem('language', locale.substring(0, 2))
}

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        // we init with resources
        preload: ['en'],
        lng: localStorage.getItem('language'),
        fallbackLng: "en",
        debug: true,
        wait: true,
        initImmediate: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },
        backend: {
            backends: [
                LocalStorageBackend,  // primary
                HttpApi               // fallback
            ],
            backendOptions: [
                {
                    // prefix for stored languages
                    prefix: 'i18next_res_',

                    // expiration
                    expirationTime: 1000 * 60 * 60 * 24 * 7,
                    // expirationTime: 7*24*60*60*1000,

                    // Version applied to all languages, can be overriden using the option `versions`
                    defaultVersion: '',

                    // language versions
                    versions: {},

                    // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
                    store: window.localStorage
                },
                {
                    loadPath: '/locales/{{lng}}/{{ns}}.json', // xhr load path for my own fallback
                }
            ]
        },
        react: {
            wait: true,
            useSuspense: true
        }
    }, (err, t) => {
         console.info('i18next.language', i18n.language, err)
    });

export default i18n;