import * as actionType from '../actions/actions'
import {updateObject} from "../utility";
import {List, Map} from 'immutable';

const initialState = {

    call_request: Map({}),
    subcalls: null,
    subcall: null,
    senderStreams: null,
    senderStreamsMuted: false,
    receiverStreams: {},
    messages: List([]),
    feed: null,
    config: Map({"iceServers": [{urls: ['stun:stun.l.google.com:19302']}]}),
    streamConstraints: {
        video: {
            width: { min: 200, ideal: 400, max: 600 },
            height: { min: 150, ideal: 300, max: 450 }
        },
        audio: true
    }
}

const calls = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPDATE_CALL_REQUEST:
            return updateObject(state, {call_request: action.data});
        case actionType.UPDATE_SUBCALLS:
            return updateObject(state, {subcalls: action.data});
        case actionType.UPDATE_SUBCALL:
            return {
                ...state,
                subcall: action.data.get('id')
            };
        // return updateObject(state, {subcall: action.data});
        case actionType.UPDATE_SENDER_STREAM:
            return updateObject(state, {senderStreams: action.data});
        case actionType.UPDATE_RECEIVER_STREAMS:
            return {
                ...state,
                receiverStreams: {
                    ...state.receiverStreams,
                    [action.data.id]: action.data.stream
                }
            };
        case actionType.UPDATE_CONFIG:
            return updateObject(state, {config: action.data});
        case actionType.UPDATE_SENDER_STREAMS_MUTED:
            return updateObject(state, {senderStreamsMuted: action.data});
        case actionType.UPDATE_MESSAGES:
            return updateObject(state, {messages: action.data});
        case actionType.HANG_UP:
            return {
                ...state,
                subcall: null,
                senderStreamsMuted: false,
                subcalls: null,
                call_request: Map({}),
                senderStreams: null,
                receiverStreams: {},
                messages: List([]),
                feed: null
            };
        case actionType.UPDATE_FEED:
            return updateObject(state, {feed: action.data})
        default:
            return state
    }
};

export default calls;