import React, {Suspense} from 'react';
import './i18n';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import thunk from 'redux-thunk';
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import auth from "./store/reducer/auth";
import {SnackbarProvider} from "notistack";
import {createMuiTheme} from '@material-ui/core/styles';
import Loading from "./components/library/Loading/Loading";
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiThemeProvider} from "@material-ui/core";
import data from "./store/reducer/data";
import Login from "./components/Login/Login";
import SignUpPage from "./components/SignUpPage/SignUpPage";
import socketIOSubscriberMiddleware from "./store/socketIOmiddleware";
import SocketClient from './SocketClient/SocketClient'
import calls from "./store/reducer/calls";
import WebRtcMiddleware from "./store/WebRtcmiddleware";
import notification from "./store/reducer/notification";
import Notifier from "./Notifier/notifier";
import {I18nextProvider} from "react-i18next";

const rootReducer = combineReducers({
    auth: auth,
    data: data,
    calls: calls,
    notification: notification,
});

const logger = store => {
    return next => {
        return action => {
            // console.log('[Middleware] Dispatching', action);
            const result = next(action);
            // console.log('[Middleware] next state', store.getState());
            return result;
        }
    }
};

const sio = new SocketClient();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk, socketIOSubscriberMiddleware(sio), WebRtcMiddleware())));


// const theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark'


const Theme = createMuiTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: '#2e7d32',
                },
                '&$active': {
                    color: '#4caf50',
                },
            },
            active: {},
            completed: {},
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: '#FFF',
            // light: '#fceb55',
            // dark: '#fdd835',
            // contrastText: '#FFF',
        },
        secondary: {
            main: '#03a9f4',
            // dark: '#039be5',
            // light: '#4fc3f7',
            // contrastText: '#FFF',
        },
        error: {
            main: red[600],
            dark: red[800],
            light: red[600],
            // contrastText: '#FFF',

        },
        success: {
            main: green[700],
            dark: green[800],
            light: green[600],
            // contrastText: '#FFF',

        },
        // background: {
        //     default: "#1E1E1E"
        // },
        // background:{
        //     main: '#757575',
        //   light: '#bdbdbd',
        // },
        test: {
            main: '#af0a61',
            dark: '#1769aa',
            light: '#4dabf5',
        },
    },
});


const DarkTheme = createMuiTheme({
        overrides: {
            MuiStepIcon: {
                root: {
                    '&$completed': {
                        color: '#2e7d32',
                    },
                    '&$active': {
                        color: '#4caf50',
                    },
                },
                active: {},
                completed: {},
            },
        },
        palette: {
            type: 'dark',
            primary: {
                main: '#ffffff',
            },
            secondary: {
                main: '#e53935',
            },
        },
        props: {
            MuiAppBar: {
                color: 'transparent',
            },
            MuiList: {
                dense: true,
            },
            MuiMenuItem: {
                dense: true,
            },
            MuiTable: {
                size: 'small',
            },
            MuiButton: {
                size: 'small',
            },
            MuiButtonGroup: {
                size: 'small',
            },
            MuiCheckbox: {
                size: 'small',
            },
            MuiFab: {
                size: 'small',
            },
            MuiFormControl: {
                margin: 'dense',
                size: 'small',
            },
            MuiFormHelperText: {
                margin: 'dense',
            },
            MuiIconButton: {
                size: 'small',
            },
            MuiInputBase: {
                margin: 'dense',
            },
            MuiInputLabel: {
                margin: 'dense',
            },
            MuiRadio: {
                size: 'small',
            },
            MuiSwitch: {
                size: 'small',
            },
            MuiTextField: {
                margin: 'dense',
                size: 'small',
            },
        },
        shape: {
            borderRadius: 4,
        },
    }
);

let root = document.documentElement;
root.style.setProperty('--primary_color', DarkTheme.palette.primary.main);


ReactDOM.render(
    <React.StrictMode>
            <MuiThemeProvider theme={DarkTheme}>
                <CssBaseline/>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
                        {/*<I18nextProvider i18n={i18n}>*/}
                            <Suspense fallback={<Loading/>}>
                                <SnackbarProvider maxSnack={4} anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
                                    <Notifier/>
                                {
                                    window.location.pathname === '/signup'?
                                        <SignUpPage/>
                                        :
                                        <Login>
                                            <App/>
                                        </Login>
                                }
                                </SnackbarProvider>
                            </Suspense>
                        {/*</I18nextProvider>*/}
                    </SnackbarProvider>
                </Provider>
            </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
