import React, {Component} from "react";
import './Chat.css'
import {sendMessage} from "../../../store/actions/calls";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Fab from "@material-ui/core/Fab";
import ChatIcon from '@material-ui/icons/Chat';
import {Badge, Paper, TextField} from "@material-ui/core";
import Message from "./Message/Message";
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import * as _ from 'lodash'

class Chat extends Component {

    state = {
        open: false,
        message: '',
        unreadMessages: 0
    };

    messageList = React.createRef()
    textField = React.createRef()

    constructor(props) {
        super(props);
        this.keyPress = this.keyPress.bind(this);
    }

    openChat = () => {
        this.setState(oldState => ({open: !oldState.open}))
            console.debug('trySetFocus')
        if (this.textField.current){
            console.debug('setFocus')
            this.textField.current.focus()
        }
    }


    sendMessage = () => {
        if (this.state.message !== '') {
            this.props.sendMessage(this.state.message).then(() => {
                    this.setState({message: ''})
                    this.scroll()
                }
            )
        }
    }

    keyPress(e){
        console.debug(e.keyCode)
        if(e.keyCode == 13){
            this.sendMessage()
        }
    }


    componentDidMount() {
        this.messageList.current.scrollTop = this.messageList.current.scrollHeight
        this.messageList.current.addEventListener('scroll', this.resetCounter)
    }

    resetCounter = (event) => {
        console.debug('down')
        var element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight && this.state.open) {
            if (this.state.unreadMessages > 0) {
                this.setState({unreadMessages: 0})
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.messages.size > prevProps.messages.size) {
            this.scroll()
        }
        if (this.state.open !== prevState.open && this.state.open) {
            this.setState({unreadMessages: 0})
        }
    }

    scroll = () => {
        const max = this.messageList.current.scrollTop + this.messageList.current.clientHeight + 108
        const min = this.messageList.current.scrollTop + this.messageList.current.clientHeight
        if (_.inRange(this.messageList.current.scrollHeight, min, max) && this.state.open) {
            this.messageList.current.scrollTop = this.messageList.current.scrollHeight
        } else if(this.props.messages.last().get('contactTo') === this.props.user.get('id') || !this.state.open){
            this.setState(oldState => ({unreadMessages: oldState.unreadMessages + 1}))
        }
    }

    changeTextHandler = (text) => {
        this.setState({message: text})
    }


    render() {
        const {t} = this.props;


        let messageList;
        if (this.props.messages.size > 0) {
            messageList = this.props.messages.map(m => {
                return (
                    <Message
                        message={m}
                        user={this.props.user}
                    />
                )
            })
        }
        return (
            <div className='chat'>
                <Paper className={this.state.open ? 'chatBody chatOpen' : 'chatBody chatClose'}>
                    {/*<div className={'chatHeader'}>*/}
                    {/*<Typography variant={"h5"}*/}
                    {/*            className={'chatContactFrom'}>{getFromCall(this.props.call_request, this.props.user)}</Typography>*/}
                    {/*</div>*/}
                    <div className={'messageList'} ref={this.messageList}>
                        <div className={'chatList'}>
                            {messageList}
                        </div>
                    </div>
                    <div className={'sendMessage'}>
                        <TextField
                            id="textFieldNewMessage"
                            value={this.state.message}
                            onChange={(event) => this.changeTextHandler(event.target.value)}
                            placeholder={t('Messaggio')}
                            multiline
                            rowsMax={3}
                            fullWidth={true}
                            margin={"none"}
                            autoFocus
                            inputRef={this.textField}
                            onKeyDown={this.keyPress}
                        />
                        <SendRoundedIcon style={{alignSelf: 'flex-end', margin:'0 0 10px 5px'}} onClick={() => this.sendMessage()}/>
                    </div>
                </Paper>
                <Badge badgeContent={this.state.unreadMessages} color="secondary"
                       anchorOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                       }}
                       overlap="circle"
                >
                    <Fab className={'fab'} onClick={() => this.openChat()}>
                        <ChatIcon/>
                    </Fab>
                </Badge>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user'),
        call_request: state.calls.call_request,
        messages: state.calls.messages,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (...args) => dispatch(sendMessage(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Chat));