import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";
import React from "react";
import PhoneMissedRoundedIcon from '@material-ui/icons/PhoneMissedRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import CallEndRoundedIcon from '@material-ui/icons/CallEndRounded';
import PhoneDisabledRoundedIcon from '@material-ui/icons/PhoneDisabledRounded';
import BlockIcon from '@material-ui/icons/Block';

let call_state = (s, class_name) => {
    switch (s) {
        case -3:
            //Disconnected
            return (<PhoneDisabledRoundedIcon style={{color: red[700], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)
        case -2:
            //Disconnected
            return (<PhoneDisabledRoundedIcon style={{color: red[700], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)
        case -1 :
            //Declined
            return (<BlockIcon style={{color: red[400], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)

        case 1:
            //Sent
            return (<PhoneRoundedIcon style={{color: yellow[700], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)

        case 2:
            //Accepted
            return (<PhoneRoundedIcon style={{color: green[500], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)

        case 3:
            //Pending
            return (<PhoneRoundedIcon style={{color: yellow[700], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)

        case 4:
            //In progress
            return (<PhoneRoundedIcon style={{color: green[600], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)

        case 5:
            //Hang-up ok
            return (<CallEndRoundedIcon style={{color: red[400], marginRight: '7px', marginLeft: '15px'}} className={class_name}/>)

        default:
            return null
    }
}

export default call_state;