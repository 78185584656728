import React, {useState} from "react";
import './ToolWindow.css'
import {withTranslation} from "react-i18next";
import CreateIcon from '@material-ui/icons/Create';
// import Rubber from "../Icon/Rubber";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {BlockPicker} from 'react-color';
import {ButtonBase, IconButton} from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';

const ToolWindow = (props) => {

    const [showColorPicker, setShowColorPicker] = useState(false)

    const marks = [
        {
            value: 10,
            label: '10px',
        },
        {
            value: 20,
            label: '20px',
        },
        {
            value: 40,
            label: '40px',
        }
    ];


    const onSetVisibilityColorPicker = () => {
        setShowColorPicker(!showColorPicker)
    }


    return (
        <React.Fragment>

            <Paper className={`toolWindow ${props.visible ? ' toolWindowExpanded' : ''}`} variant={"elevation"}
                   elevation={9}>
                <IconButton onClick={() => props.showTools()} size={"medium"} className={'iconButtonExpanded'}>
                    {props.visible ? <UnfoldLessIcon fontSize="inherit"/> :
                        <UnfoldMoreIcon fontSize="inherit"/>}
                </IconButton>
                <Divider variant={"fullWidth"}/>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={props.toolProps.get('tool')}
                    exclusive size={"small"}
                    onChange={(event, value) => props.onToolPropsChange('tool', value)}
                >
                    <ToggleButton value={1} aria-label="list">
                        <CreateIcon/>
                    </ToggleButton>
                    {/*<ToggleButton value={2} aria-label="module">*/}
                    {/*    <Rubber/>*/}
                    {/*</ToggleButton>*/}
                </ToggleButtonGroup>
                <Divider variant={"fullWidth"}/>
                <ButtonBase onClick={props.clearAll}>
                    <DeleteForeverIcon className={'toolIcon'} />
                </ButtonBase>
                <Divider variant={"fullWidth"}/>
                {/*<Rubber className={'toolIcon'} onClick={onSetVisibilityColorPicker}/>*/}
                <div className={'colorButton'} onClick={onSetVisibilityColorPicker}>
                    <div className={'circle'}/>
                    <div className={'color'} style={{backgroundColor: props.toolProps.get('toolColor')}}/>
                </div>
                <Divider variant={"fullWidth"}/>
                <div className={'toolSize'}>
                    <Slider
                        value={props.toolProps.get('toolSize')}
                        step={5}
                        max={40}
                        min={5}
                        valueLabelDisplay="auto"
                        marks={marks}
                        orientation="vertical"
                        onChange={(event, value) => props.onToolPropsChange('toolSize', value)}

                    />
                </div>
            </Paper>
            <Paper className={'colorPicker'}
                   style={{visibility: showColorPicker && props.visible ? 'visible' : 'hidden'}}>
                <BlockPicker
                    color={props.toolProps.get('toolColor')}
                    triangle={'hide'}
                    colors={['#df221d', '#9c27b0', '#2196f3', '#00bcd4', '#2CCCE4', '#43a047', '#ffeb3b', '#ffb300', '#f4511e', '#FFFFFF']}
                    onChange={(color, event) => props.onToolPropsChange('toolColor', color.hex)}
                />
            </Paper>
            {/*<Paper className={'showTool'} onClick={()=>props.showTools()} variant={"outlined"}>*/}
            {/*    <ArrowForwardIosIcon className={'toolIcon'} onClick={()=>props.showTools()}/>*/}
            {/*</Paper>*/}

        </React.Fragment>


    )
}

export default withTranslation()(ToolWindow);