import React, {Component} from "react";
import './DeviceMng.css'
import {withTranslation} from "react-i18next";
import IconTpButton from "../library/IconTpButton/IconTpButton";
import SmartGlasses from "../library/Icon/SmartGlasses";
import StreaMaze from "../library/Icon/StreamazeIcon";
import ExternalCamera from "../library/Icon/ExternalCamera";
import AppsIcon from '@material-ui/icons/Apps';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import {
    abortPairingSmartGlasses,
    addNewExternalCamera,
    devicePairedEvents,
    getContacts,
    getContainer,
    getDevices,
    pairingSmartGlasses,
    pairingSmartphone,
    updateContacts
} from "../../store/actions/data";
import {connect} from "react-redux";
import DeviceGroup from "../library/DeviceGroup/DeviceGroup";
import {List} from 'immutable'
import LoopIcon from "@material-ui/icons/Loop";
import QRCode from 'qrcode.react';
import icon from '../library/Icon/logo.png'
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

class DeviceMng extends Component {

    state = {
        actFilter: 'all',
        popUpNewDevice: false,
        newDevice: 1,
        qrcodeCodeSM: null,
        qrcodeCodeSP: null,
        ipAddress: '',
    }

    componentDidMount() {
        this.props.getContacts();
        this.props.getContainer();
        this.props.getDevices();
        // this.props.session.on('devicePaired', this.onAbortAddDevice)
    }

    componentWillUnmount() {
        if (this.state.qrcodeCodeSM) {
            this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSM)
        }
        if (this.state.qrcodeCodeSP) {
            this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSP)
        }
    }

    refreshData = () => {
        this.props.getContacts();
        this.props.getContainer();
        this.props.getDevices();
    }

    addDevice = () => {
        this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSP)
        this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSM).then(() =>
            this.setState({popUpNewDevice: false}))
    }

    onChangeFilter = (value) => {
        this.setState({actFilter: value})
    }

    handleChangeDevice = (e, value) => {
        this.setState({newDevice: value})
    }

    onOpenPopUp = (groupId) => {
        this.props.devicePairedEvents().then((pairCode) => {
                console.info(`SmartGlasses added ${pairCode}`)
                if (pairCode === this.state.qrcodeCodeSM) {
                    this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSP).then(() => {
                        this.setState({popUpNewDevice: false, qrcodeCodeSP: null})
                    })
                } else if (pairCode === this.state.qrcodeCodeSP){
                    this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSM).then(() => {
                        this.setState({popUpNewDevice: false, qrcodeCodeSM: null})
                    })
                } else {
                    console.error(`${pairCode} non valido`)
                }
            }
        )
        this.props.pairingSmartGlasses('New device', groupId).then((res) =>
            this.setState({qrcodeCodeSM: res, popUpNewDevice: true})
        )
        this.props.pairingSmartphone('New device', groupId).then((res) =>
            this.setState({qrcodeCodeSP: res, popUpNewDevice: true})
        )
    }

    onAbortAddDevice = () => {
        if (this.state.qrcodeCodeSM) {
            this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSM).then(() =>
                console.debug('')
            )
        }
        if (this.state.qrcodeCodeSP) {
            this.props.abortPairingSmartGlasses(this.state.qrcodeCodeSP).then(() =>
                console.debug('')
            )
        }
        this.setState({popUpNewDevice: false, qrcodeCodeSM: null})
        this.setState({popUpNewDevice: false, qrcodeCodeSP: null})
    }

    // onClosePopUp = () => {
    //     this.setState({popUpNewDevice: false})
    // }

    onAddExtCamera = () => {
        this.props.addNewExternalCamera(this.state.ipAddress).then(() =>
            this.setState({popUpNewDevice: false})
        )
    }

    onInputText = (e) => {
        this.setState({ipAddress: e.target.value})
    }

    render() {
        const {t} = this.props

        let cnt_list = null
        if (this.props.container) {
            const groupList = this.props.user.get('type') === 'ADMIN' ?
                this.props.container.get('groups', List([])).sortBy(val => val.get('title')) :
                List([this.props.container.get('groups', List([])).find(gr => gr.get('id') === this.props.user.getIn(['user','assignedGroup']), List([]))])
            console.log("id", groupList)
            cnt_list = groupList.map((group) => {
                    if(group !== undefined)
                    return (
                        <DeviceGroup
                            key={'grp ' + group.get('id')}
                            dev={this.props.devices.filter(dev => dev.get('assignedGroup') === group.get('id'))}
                            group={group}
                            filter={this.state.actFilter}
                            onOpenPopUp={(...args) => this.onOpenPopUp(...args)}
                        />
                    )

                })

        }

        let popUpDevice
        switch (this.state.newDevice) {
            case 1:
                popUpDevice = <React.Fragment>
                    <div className={'instruction'}>
                        <Typography>{t("Istruzioni per l'accoppiamento")}</Typography>
                        <Typography>{t('1 - Indossare gli occhiali')}</Typography>
                        <Typography>{t("2 - Aprire l'app 'LookToAid'")}</Typography>
                        <Typography>{t("3 - Inquadrare il QrCode")}</Typography>
                        <Typography>{t("4 - Attendere che si chiuda il PopUp")}</Typography>
                    </div>
                    {this.state.qrcodeCodeSM ?
                        <QRCode
                            value={this.state.qrcodeCodeSM ? this.state.qrcodeCodeSM : ''}
                            includeMargin={true}
                            level={'H'}
                            size={310}
                            imageSettings={{
                                src: icon,
                                x: null,
                                y: null,
                                height: 100,
                                width: 100,
                                excavate: true,
                            }}
                        /> :
                        <Typography>{t('Pairing non disponibile')}</Typography>}
                </React.Fragment>
                break
            case 2:
                popUpDevice = <React.Fragment>
                    <div className={'instruction'}>
                        <Typography>{t("Istruzioni per l'accoppiamento")}</Typography>
                        <Typography>{t('1 - Verificare che la camera sia accesa')}</Typography>
                        <Typography>{t("2 - Collegarla alla rete della StreamMaze")}</Typography>
                        <Typography>{t("3 - Inserire l'indirizzo rtsp della camera")}</Typography>
                        <Typography>{t("4 - Salvare tramite il pulsante salva")}</Typography>
                    </div>
                    <TextField
                        label={t('Indirizzo IP')}
                        variant={"outlined"}
                        multiline
                        rows={14}
                        style={{width: '100%'}}
                        value={this.state.ipAddress}
                        onChange={this.onInputText}
                        type={'url'}

                    />
                </React.Fragment>
                break
            case 3:
                popUpDevice = <React.Fragment>
                    <div className={'instruction'}>
                        <Typography>{t("Istruzioni per l'accoppiamento")}</Typography>
                        <Typography>{t("1 - Aprire l'app 'LookToAid'")}</Typography>
                        <Typography>{t("2 - Inquadrare il QrCode")}</Typography>
                        <Typography>{t("3 - Attendere che si chiuda il PopUp")}</Typography>
                        <Typography> </Typography>
                    </div>
                    {this.state.qrcodeCodeSP ?
                        <QRCode
                            value={this.state.qrcodeCodeSP ? this.state.qrcodeCodeSP : ''}
                            includeMargin={true}
                            level={'H'}
                            size={310}
                            imageSettings={{
                                src: icon,
                                x: null,
                                y: null,
                                height: 100,
                                width: 100,
                                excavate: true,
                            }}
                        /> :
                        <Typography>{t('Pairing non disponibile')}</Typography>}
                </React.Fragment>
                break
        }

        return (
            <React.Fragment>
                <div className={'deviceMng'}>
                    <div className={'header_bar'}>
                        <Typography variant={'h4'}>{t('Gestione device')}</Typography>
                        <div>
                            <IconTpButton onClick={this.refreshData} icon={LoopIcon} tooltip={t('Aggiorna')}/>
                        </div>
                    </div>
                    <div className={'body'}>
                        <div className={'filter'}>
                            <Tooltip placement={"top"} title={t('Tutti')}>
                                <Paper
                                    className={this.state.actFilter === 'all' ? 'deviceTypeActive' : 'deviceType'}
                                    onClick={() => this.onChangeFilter('all')}
                                >
                                    <AppsIcon className={'icon'}/>
                                </Paper>
                            </Tooltip>
                            <Tooltip placement={"top"} title={t('Smart Glasses')}>
                                <Paper
                                    className={this.state.actFilter === 'SG' ? 'deviceTypeActive' : 'deviceType'}
                                    onClick={() => this.onChangeFilter('SG')}>
                                    <SmartGlasses className={'icon'}/>
                                </Paper>
                            </Tooltip>
                            <Tooltip placement={"top"} title={t('StreaMaze')}>
                                <Paper
                                    className={this.state.actFilter === 'SA' ? 'deviceTypeActive' : 'deviceType'}
                                    onClick={() => this.onChangeFilter('SA')}>
                                    <StreaMaze style={{height: '50px', width: '50px', margin: '5px'}}/>
                                </Paper>
                            </Tooltip>
                            <Tooltip placement={"top"} title={t('Camera Esterna')}>
                                <Paper
                                    className={this.state.actFilter === 'EC' ? 'deviceTypeActive' : 'deviceType'}
                                    onClick={() => this.onChangeFilter('EC')}>
                                    <ExternalCamera className={'icon'}/>
                                </Paper>
                            </Tooltip>
                            <Tooltip placement={"top"} title={t('Telefono')}>
                                <Paper
                                    className={this.state.actFilter === 'SP' ? 'deviceTypeActive' : 'deviceType'}
                                    onClick={() => this.onChangeFilter('SP')}>
                                    <PhoneAndroidIcon className={'icon'}/>
                                </Paper>
                            </Tooltip>
                        </div>
                        <div className={'groupsView'}>
                            {cnt_list}
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.popUpNewDevice}
                    className={'add_device_dialog'}
                    // onClose={}
                    onExited={this.onAbortAddDevice}
                >
                    <DialogTitle>
                        {t('Aggiunta nuovo device')}
                        <ToggleButtonGroup
                            value={this.state.newDevice}
                            exclusive
                            onChange={this.handleChangeDevice}
                            style={{margin: '0 0 0 10px'}}
                        >
                            <ToggleButton value={1}>
                                <SmartGlasses/>
                            </ToggleButton>
                            <ToggleButton value={3}>
                                <PhoneAndroidIcon/>
                            </ToggleButton>
                            <ToggleButton value={2}>
                                <ExternalCamera/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </DialogTitle>
                    <DialogContent className={'dialogContent'}>
                        {popUpDevice}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.onAbortAddDevice}
                        >
                            {t('Abort')}
                        </Button>
                        {
                            this.state.newDevice === 2 ?
                                <Button
                                    variant={"outlined"}
                                    disabled={this.state.ipAddress === ''}
                                    onClick={this.onAddExtCamera}
                                >
                                    {t('Salva')}
                                </Button>
                                : null
                        }
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )

    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        contacts: state.data.contacts,
        devices: state.data.devices,
        container: state.data.container,
        am: state.auth.am,
        session: state.auth.session,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getContacts: () => dispatch(getContacts()),
        getContainer: () => dispatch(getContainer()),
        getDevices: () => dispatch(getDevices()),
        updateContacts: (...args) => dispatch(updateContacts(...args)),
        addNewExternalCamera: (...args) => dispatch(addNewExternalCamera(...args)),
        abortPairingSmartGlasses: (...args) => dispatch(abortPairingSmartGlasses(...args)),
        pairingSmartGlasses: (...args) => dispatch(pairingSmartGlasses(...args)),
        pairingSmartphone: (...args) => dispatch(pairingSmartphone(...args)),
        devicePairedEvents: (...args) => dispatch(devicePairedEvents(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DeviceMng));
