import React, {useState} from "react";
import './CallAction.css'
import {withTranslation} from "react-i18next";
import Fab from "@material-ui/core/Fab";
import CallEndSharpIcon from '@material-ui/icons/CallEndSharp';
import Brightness6Icon from '@material-ui/icons/Brightness6';
import MicOffIcon from '@material-ui/icons/MicOff';
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {Paper} from "@material-ui/core";
import MicIcon from '@material-ui/icons/Mic';
import Button from "@material-ui/core/Button";

const CallAction = (props) => {
    const {t} = props;
    const [filterWindow, setFilterWindow] = useState(false)

    const openFilterWindow = () => {
        setFilterWindow(!filterWindow)
    }

    return (
        <React.Fragment>
            <div className={'callAction'}>
                <div>
                    <Fab size="small" color="secondary" className={'bottom'} onClick={openFilterWindow}>
                        <Brightness6Icon/>
                    </Fab>
                    <Paper className={filterWindow ? 'videoFilters videoFiltersOpen' : 'videoFilters videoFiltersClose'}
                           variant={"outlined"}>
                        <div className={'filter'}>
                            <Typography>{t('Luminosità')}</Typography>
                            <Slider
                                min={50}
                                max={200}
                                value={props.videoFilter.get('brightness')}
                                onChange={(e, v) => props.onChange('brightness', v)}
                            />
                        </div>
                        <div className={'filter'}>
                            <Typography>{t('Contrasto')}</Typography>
                            <Slider
                                min={50}
                                max={200}
                                value={props.videoFilter.get('contrast')}
                                onChange={(e, v) => props.onChange('contrast', v)}
                            />
                        </div>
                        <div className={'filter'}>
                            <Typography>{t('Saturazione')}</Typography>
                            <Slider
                                min={50}
                                max={200}
                                value={props.videoFilter.get('saturation')}
                                onChange={(e, v) => props.onChange('saturation', v)}
                            />
                        </div>
                        <Button fullWidth onClick={()=>props.resetFilter()}>{t('Reset')}</Button>
                    </Paper>
                </div>
                <Fab size='large' color="secondary" className={'bottom'} onClick={()=>props.hangUp()}>
                    <CallEndSharpIcon/>
                </Fab>
                <Fab size="small" color="secondary" className={'bottom'} onClick={props.onMicMute}>
                    {props.micMute?<MicOffIcon/>:<MicIcon/>}
                </Fab>
            </div>

        </React.Fragment>
    )
}

export default withTranslation()(CallAction);