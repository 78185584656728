/**
 * Middleware del cazzo
 * @param socket
 * @returns {function({dispatch?: *}): function(*): function(*=): *}
 */

import {fromJS} from "immutable";

const socketIOSubscriberMiddleware = socket => ({dispatch}) => next => action => {
    /**
     * {
     *     type: ACTION,
     *     socket: {
     *       subscribe:{
     *          event: 'event',
     *          handle: promise,
     *          responseType: ACTION_ON_EVENT
     *       }
     *       unsubscribe:{
     *          event: 'event'
     *       }
     *       emit:{
     *          event: 'event',
     *          handle: promise
     *       }
     *     }
     *     data: {}
     * }
     */

    if (action.type === 'SOCKET_CONNECT') {
        console.info('SOCKET_CONNECT')
        socket.connect()
            .then(() => dispatch({type: 'SOCKET_CONNECTED', data: true}))
    }
    if (action.type === 'SOCKET_DISCONNECT') {
        socket.disconnect()
            .then(() => dispatch({type: 'SOCKET_DISCONNECTED', data: false}))
    }
    if (action.socket) {
        const {
            socket: {subscribe, unsubscribe, emit},
        } = action;
        if (subscribe) {
            const {event, handle, responseType} = subscribe;
            if (event && handle) {
                console.debug('subscribe with handle', event)
                socket.on(event, handle);
            } else if (event && responseType) {
                console.debug('subscribe with responseType', event, responseType)
                socket.on(event, socketResponse => {
                    dispatch({type: responseType, data: fromJS(socketResponse)});
                });
            }
        }
        if (unsubscribe) {
            const {event} = unsubscribe;
            console.debug('unsubscribe', event)
            socket.removeListener(event);
        }
        if (emit) {
            const {event, handle} = emit;
            if (event && handle) {
                socket.emit(event, action.data).then(handle);
            } else {
                socket.emit(event, action.data);
            }

        }
    }

    return next(action);
};

export default socketIOSubscriberMiddleware;