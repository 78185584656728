import React, {Component} from "react";
import './PopUpMemberCallPrg.css';
import {ButtonBase, Paper} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Fab from "@material-ui/core/Fab";
import CallEndSharpIcon from "@material-ui/icons/CallEndSharp";
import Chat from "../ChatV2/Chat";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import moment from "moment";
import {HangUpCall, memberChangeSubscription} from "../../../store/actions/calls";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SmartGlasses from "../Icon/SmartGlasses";
import StreaMaze from "../Icon/StreamazeIcon";
import ExternalCamera from "../Icon/ExternalCamera";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {fromJS, List} from "immutable";

class PopUpMemberCallPrg extends Component {

    state = {
        duration: null,
    }

    // handleNewUserMessage = (newMessage) => {
    //     addResponseMessage('Welcome to this awesome chat!');
    //     setBadgeCount(5)
    //     addLinkSnippet({
    //         title: 'My awesome link',
    //         link: 'https://openbase.io/js/so-react-chat-widget',
    //         target: '_blank'
    //     })
    //
    // };

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.props.call_request) {
                const startDate = moment(this.props.call_request.get('tsStart'), 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                const currentDate = moment();
                if(startDate.isValid()){
                    this.setState({duration: moment.utc(currentDate.diff(startDate)).format("HH:mm:ss")});
                }
            }
        }, 1000);
    }

    onSourceSubscribe = (sc) => {
        this.props.changeSubscription(sc.get('id'))
    }

    render() {

        let subcalls = null;
        if (this.props.subcalls && this.props.contacts) {
            subcalls = this.props.subcalls.filter((s, i) => i !== 0).map((s, index) => {
                const cnt = this.props.contacts.get(this.props.contacts.findIndex(c => c.get('id') === s.get('contactFrom')))
                let device_icon
                if (cnt.get('type') === 'SG') {
                    device_icon = <SmartGlasses style={{color: '#FFF', height: '40px', width: '48px'}}/>
                } else if (cnt.get('type') === 'SA') {
                    device_icon = <StreaMaze style={{color: '#FFF', height: '48px', width: '48px'}}/>
                } else if (cnt.get('type') === 'EC') {
                    device_icon = <ExternalCamera style={{color: '#FFF', height: '48px', width: '48px'}}/>
                } else if (cnt.get('type') === 'SP') {
                    device_icon = <PhoneAndroidIcon style={{color: '#FFF', height: '48px', width: '48px'}}/>
                } else {
                    device_icon = <HelpOutlineIcon style={{color: '#FFF', height: '48px', width: '48px'}}/>
                }
                let classname
                if (s.get('state') !== 3) {
                    classname = 'outerSource sourceOffline'
                } else if (s.get('state') === 3 && this.props.feed === s.get('id')) {
                    classname = 'outerSource sourceSelected'
                } else {
                    classname = 'outerSource sourceAvailable'
                }
                return (
                    // <div style={{display:"flex", flexDirection:"column"}}>
                    <ButtonBase
                        key={s.get('id')}
                        className={classname}
                        disabled={s.get('state') !== 3}
                    >
                        <Paper className={'innerSource'}
                               variant={"outlined"}
                               onClick={() => this.onSourceSubscribe(s)}
                        >
                            {device_icon}
                            <Typography>{cnt.get('name')}</Typography>
                        </Paper>

                    </ButtonBase>
                    //<ButtonBase
                    //    key={s.get('id') + '2' }
                    //    className={classname}
                    //    disabled={s.get('state') !== 3}
                    //>
                    //    <Paper
                    //        className={'innerSource'}
                    //        variant={"outlined"}
                    //        onClick={() => this.onSourceSubscribe(s)}
                    //    >
                    //        {device_icon}
                    //        <Typography>{cnt.get('name')}</Typography>
                    //    </Paper>
                    //</ButtonBase>
                    // </div>
                )
            })
        }
        return (
            this.props.call_request && this.props.call_request.get('state') === 2 &&
            <div className={"MemberCallPanel"}>
                <Paper className={'PopUpMemberCallPrg'}>
                    <Paper className={'infoCall'}>
                        <Typography
                            variant={"h4"}
                            className={'name'}>
                            Luca Rossetto
                            {/*{this.props.call_request.get('contactFrom') === this.props.user.get('id') ? this.props.call_request.get('contactToFullName') : this.props.call_request.get('contactFromFullName')}*/}
                        </Typography>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <FiberManualRecordIcon style={{margin:"0 10px 0 0"}}/>
                            <Typography>{this.state.duration}</Typography>

                        </div>


                    </Paper>
                    <Fab size='large' color="secondary" className={'button'} onClick={() => this.props.HangUpCall()}>
                        <CallEndSharpIcon/>
                    </Fab>
                    <div className={'button'}>
                        <Chat/>
                    </div>
                </Paper>
                <div className={'subCallsList'}>
                    {subcalls}
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user.get('user'),
        contacts: state.data.contacts,
        call_request: state.calls.call_request,
        subcalls: state.calls.subcalls,
        feed: state.calls.feed
        // subcalls: fromJS([
        //     {   callId:1704,
        //         id:4370,
        //         contactTo:"1000000090355682",
        //         contactFrom:"b34b2367496a07a1d25581c210ccb25a",
        //         tsStart:"2021-03-04T11:14:57.300163+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4371,
        //         contactTo:"1000000090355682",
        //         contactFrom:"b3c2eb056870a7a4",
        //         tsStart:"2021-03-04T11:14:56.935178+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4372,
        //         contactTo:"1000000090355682",
        //         contactFrom:"90cc643a5d3e8feb1f75",
        //         tsStart:"2021-03-04T11:14:53.899762+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4373,
        //         contactTo:"1000000090355682",
        //         contactFrom:"67786878a7a2f0ce4e5a",
        //         tsStart:"2021-03-04T11:14:54.928360+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4374,
        //         contactTo:"1000000090355682",
        //         contactFrom:"0bac5ea9397134ab48cd",
        //         tsStart:"2021-03-04T11:14:57.167478+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4374,
        //         contactTo:"1000000090355682",
        //         contactFrom:"0bac5ea9397134ab48cd",
        //         tsStart:"2021-03-04T11:14:57.167478+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4374,
        //         contactTo:"1000000090355682",
        //         contactFrom:"0bac5ea9397134ab48cd",
        //         tsStart:"2021-03-04T11:14:57.167478+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4374,
        //         contactTo:"1000000090355682",
        //         contactFrom:"0bac5ea9397134ab48cd",
        //         tsStart:"2021-03-04T11:14:57.167478+0000",
        //         tsEnd:null,
        //         state:3},{
        //         callId:1704,
        //         id:4374,
        //         contactTo:"1000000090355682",
        //         contactFrom:"0bac5ea9397134ab48cd",
        //         tsStart:"2021-03-04T11:14:57.167478+0000",
        //         tsEnd:null,
        //         state:3},
        // ])
    };
};

const mapDispatchToProps = dispatch => {
    return {
        HangUpCall: () => dispatch(HangUpCall()),
        changeSubscription: (subCallId) => dispatch(memberChangeSubscription(subCallId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PopUpMemberCallPrg));