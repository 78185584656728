import IconButton from "@material-ui/core/IconButton";
import LoopIcon from "@material-ui/icons/Loop";
import * as PropTypes from "prop-types";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {withTranslation} from "react-i18next";
import './IconTpButton.scss';
// import styles from './IconTpButton.scss'

const IconTpButton = (props) => {
    const [rotation, setRotation] = React.useState(0)

    const onRefreshClick = () => {
        setRotation(1)
        props.onClick()

    }
    const TheIcon = props.icon;
    return <Tooltip title={props.tooltip} placement="bottom" className={'IconTpButton'}>
        <IconButton edge="end" onClick={onRefreshClick} onTransitionEnd={() => setRotation(0)} rotation={rotation}>
            <TheIcon />
        </IconButton>
    </Tooltip>
}

IconTpButton.propTypes = {onClick: PropTypes.func};

export default IconTpButton;