import React, {useState} from 'react';
import './Statistic.css'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';

const StatisticBox = (props) => {

    const [open, setOpen] = useState(false)

    const Icon = props.icon

    const handleClick = () => {
        if (props.extra){
            setOpen(!open)
        }
    }

    let extra = null
    if (props.extra) {
        extra = props.extra.map((e, i, a) => {
            return (
                <React.Fragment>
                    <div className={'inlineValue'}>
                        <Typography className={'extraText noselect'}>{e.name + ': '}</Typography>
                        <Typography className={'extraText noselect'}
                                    style={{alignSelf: 'flex-end', textAlign: 'end'}}>{e.value}</Typography>
                    </div>
                    {/*{i + 1 !== a.length && <Divider style={{backgroundColor: '#e7e7e7', margin: 0}} variant={"fullWidth"}/>}*/}
                </React.Fragment>
            )
        })
    }

    return (
        <Paper className={open ? 'statisticsBox statisticsBoxExpanded' : 'statisticsBox statisticsBoxNormal'}
               style={{backgroundColor: props.color, height: open? props.extraHeight: null}} onClick={handleClick}>
            <Typography className={'statistics_box_title noselect'}>{props.title}</Typography>
            <Typography variant={"h2"} className={'statistics_box_value noselect'}>
                {props.value}
            </Typography>
            <Icon className={'statistics_box_icon'}/>
            {props.extra && open && <ExpandLessRoundedIcon className={'expandIcon'}/>}
            {props.extra && !open && <ExpandMoreRoundedIcon className={'expandIcon'}/>}
            {props.extra && <div className={'extra'}>
                {/*<FlatTable*/}
                {/*    data={props.extra}*/}
                {/*/>*/}
                {extra}
            </div>}
        </Paper>
    )
}

export default StatisticBox;