import React from "react";
import './Message.scss'
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const Message = (props) => {

    const sent = props.message.get('contactFrom') === props.user.get('id')
    const date_start = moment(props.message.get('createdAt'), 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ')

    return(
        <div className={sent? 'message sent': 'message received'}>
            <Typography className={'text'}>{props.message.get('message')}</Typography>
            <Typography variant={"body2"} className={'messDatetime'}>{date_start.format("HH:mm")}</Typography>

        </div>
    )
}

export default Message;