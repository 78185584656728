import React, {forwardRef} from "react";
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn
} from "@material-ui/icons";

export const TableIcons =
    {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
    }


export const translation = (t) => {
    return ({
        body: {
            emptyDataSourceMessage: t("Nessun record da visualizzare"),
            addTooltip: t("Aggiungi"),
            deleteTooltip: t("Elimina"),
            editTooltip: t("Modifica"),
            filterRow: {
                filterTooltip: t("Filtra")
            },
            editRow: {
                deleteText: t("Sei sicuro di voler eliminare questa riga"),
                cancelTooltip: t("Annulla"),
                saveTooltip: t("Salva")
            }
        },
        grouping: {
            placeholder: t("Trascina elemento qui..."),
            groupedBy: t("Raggruppato per")
        },
        header: {
            actions: t("Azioni")
        },
        pagination: {
            labelDisplayedRows: t('{from}-{to} di {count}'),
            labelRowsSelect: t("Righe"),
            labelRowsPerPage: t("Righe per pagina"),
            firstAriaLabel: t("Prima pagina"),
            firstTooltip: t("Prima pagina"),
            previousAriaLabel: t("Pagina precedente"),
            previousTooltip: t("Pagina precedente"),
            nextAriaLabel: t("Pagina successiva"),
            nextTooltip: t("Pagina successiva"),
            lastAriaLabel: t("Ultima pagina"),
            lastTooltip: t("Ultima pagina")
        },
        toolbar: {
            addRemoveColumns: t("Aggiungi o rimuovi colonna"),
            nRowsSelected: t('{0} righe(n) selezionate'),
            showColumnsTitle: t("Mostra colonne"),
            showColumnsAriaLabel: t("Mostra colonne"),
            exportTitle: t("Esporta"),
            exportAriaLabel: t("Esporta"),
            exportName: t("Esporta CSV"),
            searchTooltip: t("Cerca"),
            searchPlaceholder: t("Cerca")
        }
    })

}