export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_AM = 'UPDATE_AM';
export const UPDATE_ROLES = 'UPDATE_ROLES';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const RESET_DATA = 'RESET_DATA';

export const UPDATE_SESSIONS = 'UPDATE_SESSIONS';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';

export const UPDATE_CALLS = 'UPDATE_CALLS';
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS';
export const UPDATE_DEVICES = 'UPDATE_DEVICES';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const UPDATE_CALL_REQUEST = 'UPDATE_CALL_REQUEST';
export const UPDATE_STREAMS = 'UPDATE_STREAMS';
export const UPDATE_SUBCALLS = 'UPDATE_SUBCALLS';
export const UPDATE_SUBCALL = 'UPDATE_SUBCALL';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_SENDER_STREAMS_MUTED = 'UPDATE_SENDER_STREAMS_MUTED';

export const ADD_LOCAL_TRACK = 'ADD_LOCAL_TRACK';
export const CREATE_SENDER_PEER = 'CREATE_SENDER_PEER';
export const CREATE_RECEIVER_PEER = 'CREATE_RECEIVER_PEER';
export const UPDATE_SENDER_STREAM = 'UPDATE_SENDER_STREAM';
export const UPDATE_RECEIVER_STREAMS = 'UPDATE_RECEIVER_STREAMS';
export const MAKE_OFFER = 'MAKE_OFFER';
export const MAKE_ANSWER = 'MAKE_ANSWER';
export const HANDLE_OFFER = 'HANDLE_OFFER';
export const HANDLE_ANSWER = 'HANDLE_ANSWER';
export const HANDLE_CANDIDATE = 'HANDLE_CANDIDATE';
export const HANG_UP = 'HANG_UP';

export const UPDATE_FEED = 'UPDATE_FEED';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';

export const UPDATE_GROUP_STATISTIC = 'UPDATE_GROUP_STATISTIC';
export const UPDATE_USER_STATISTIC = 'UPDATE_USER_STATISTIC';
