import * as actionType from '../actions/actions'
import {fromJS, Map} from "immutable";
import {getContacts, UpdateCallRequest} from "./data";
import axios_inst from "../../axios_inst";
import {socketEmit, socketOn} from "./socketIO";
import {callAccept, handleChangeSubscription, HangUpCall, onNewCallIncoming, onNewMessage} from "./calls";
import {closeSnackbar, enqueueSnackbar} from "./notification";
import * as _ from 'lodash'

const authStart = (defaultLogin) => {
    return {
        type: actionType.AUTH_START,
        defaultLogin: defaultLogin
    }
};

const authSuccess = (user) => {
    return {
        type: actionType.AUTH_SUCCESS,
        user: user
    }
};

const UpdateUser = (user) => {
    return {
        type: actionType.UPDATE_USER,
        user: user
    }
};

const authFail = (authError) => {
    return {
        type: actionType.AUTH_FAIL,
        error: authError
    }
};

const authLogout = () => {
    return {type: actionType.AUTH_LOGOUT}
};

const updateSession = (ses) => {
    return {
        type: actionType.UPDATE_SESSIONS,
        ses: ses
    }
};

//
// const checkAuthTimeout = (expirationTime) => {
//     return dispatch => {
//         setTimeout(() => {
//             // console.debug('FORZA LOGOUT')
//             dispatch(logout())
//         }, expirationTime)
//     }
// };

export const login = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        return new Promise((resolve, reject) => {
            axios_inst.post('/signin', {
                email, password
            })
                .then(res => {
                    const dt = res.data.data;
                    dispatch({type: 'SOCKET_CONNECT'})
                    dispatch(authSuccess(fromJS(dt)))
                    resolve(dt)
                })
                .catch((error, data) => {
                    dispatch(authFail(fromJS(error)))
                    console.debug(error.response)
                    console.debug(JSON.stringify(error))
                    if (_.get(error, 'response.status') === 401) {
                        dispatch(enqueueSnackbar({
                                message: 'Username o password non corretti',
                                options: {
                                    key: new Date().getTime() + Math.random(),
                                    variant: 'error',
                                    autoHideDuration: 2000,
                                },
                            })
                        )
                    } else if (!error.status) {
                        dispatch(enqueueSnackbar({
                            message: 'Errore di comunicazione',
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 2000,
                            },
                        }))
                    }
                    reject(error)
                })
        })
    }
};

export const register = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const auth = getState().auth
            console.info("Session registering..");
            axios_inst.post('/contactSignin', {
                id: auth.user.get('id'),
                token: auth.user.get('token')
            })
                .then(res => {
                    if (!res.data.data.result) return reject("Device not authenticated")

                    const onLink = (res) => {
                        if (res === null || res === undefined) {
                            console.error('Errore durante il link del contatto')
                            reject("Errore durante il link del contatto")
                        } else {
                            console.debug("Received session id", res)
                            dispatch(updateSession(res))
                            return resolve(res);
                        }
                    }
                    dispatch(socketEmit('contact/link', auth.user.get('id'), (res) => onLink(res)))
                    dispatch(socketOn('answer', null, actionType.HANDLE_ANSWER))
                    dispatch(socketOn('candidate', null, actionType.HANDLE_CANDIDATE))
                    dispatch(socketOn('offer', null, actionType.HANDLE_OFFER))
                    dispatch(socketOn(`call/accept`, (...args) => dispatch(callAccept(...args))))
                    dispatch(socketOn(`call/decline`, () => {
                        dispatch(closeSnackbar(getState().notification.notifications.find(n => n.message === 'Call in corso') ? getState().notification.notifications.find(n => n.message === 'Call in corso').key : 0))
                        dispatch(enqueueSnackbar({
                            message: 'Call rifiutata',
                            options: {
                                key: new Date().getTime() + Math.random(),
                                variant: 'error',
                                autoHideDuration: 3000,
                            },
                        }))
                        dispatch(UpdateCallRequest(Map({})))
                    }))
                    dispatch(socketOn(`chat/on`, (...args) => dispatch(onNewMessage(...args))))
                    dispatch(socketOn('callRequest/incoming', (...args) => dispatch(onNewCallIncoming(...args))))
                    dispatch(socketOn('contacts/update', (...args) => dispatch(getContacts(...args))))
                    dispatch(socketOn('devices/update', (...args) => dispatch(getContacts(...args))))

                    dispatch(socketOn('disconnect', () => dispatch(disconnect())))
                    dispatch(socketOn('forceDisconnection', () => dispatch(disconnect(true))))
                    dispatch(socketOn('changeSubscription', (dt) => dispatch(handleChangeSubscription(dt))))
                    // sio.on('disconnect', () => {
                    //     console.warn("Socket disconnected, reset session");
                    //     sio.disconnect();
                    //     Store.setVal('sio', null);
                    //     dispatch(resetAll())
                    //     //# TODO RESET ALL
                    // })
                    // sio.on('error', error => {
                    //     console.error(error);
                    // })
                })
                .catch(error => console.error(`Session not created. ${error}`, error))
        })
    }
}


export const disconnect = (force = false) => {
    return (dispatch, getState) => {
        getState().notification.notifications.forEach(n => dispatch(closeSnackbar(n.key)))
        dispatch(enqueueSnackbar({
                message: force ? "Nuova sessione aperta su un altro device" : 'Disconnessione',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: force ? 'info' : 'warning',
                    autoHideDuration: 5000,
                },
            })
        )
        dispatch(authLogout())
        dispatch(HangUpCall())
        dispatch({type: actionType.RESET_DATA})
        dispatch({type: actionType.SOCKET_DISCONNECT})
    }
}


export const updateUser = (userData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let updatedUser = userData
            updatedUser = updatedUser.delete('attributes')
            updatedUser = updatedUser.toJS()

            axios_inst.put(`/users/${updatedUser['id']}`, updatedUser)
                .then((res) => {
                    dispatch(UpdateUser(getState().auth.user.mergeIn(['user'], updatedUser)))
                    resolve()
                })
                .catch((err) => {
                    console.error(err)
                    reject()
                })

        })
    }
}


export const changeUserPassword = (userId, oldPassword, newPassword, newPasswordCopy) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            axios_inst.post(`/passwordChange`, {userId, oldPassword, newPassword, newPasswordCopy})
                .then((res) => resolve())
                .catch((res) => reject())
        })
    }
}


// const edit_user = (user) => {
//     console.log(user)
//     return {
//         type: actionType.UPDATE_USER,
//         user: user
//     }
// }
//
//
// export const login = (userName, password) => {
//
//     return (dispatch, getState) => {
//         axios.post('/login', {username: userName, password: password})
//             .then((response) => {
//                 console.log(response.data.data)
//                 dispatch(edit_user(getState().auth.user.update(() => fromJS(response.data.data))))
//             })
//             .catch((error)=>dispatch(edit_user(getState().auth.user.update(() => Map({token:'failed'})))))
//
//         // dispatch(edit_user(getState().auth.user.updateIn(['token'], val => 'response.data.token')))
//     }
// }
//
//
export const logout = () => {
    return (dispatch, getState) => {
        const actUserId = getState().auth.user.getIn(['user', 'id'])
        dispatch(authLogout())
        // console.debug(actUserId)
        if (actUserId !== 1) {
            // console.debug("ReLogin")
            dispatch(login('AutoLogin', 'dummy', true))
        }
    }
}
