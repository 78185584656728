import React from "react";
import BatteryCharging20Icon from "@material-ui/icons/BatteryCharging20";
import Battery20Icon from "@material-ui/icons/Battery20";
import BatteryCharging30Icon from '@material-ui/icons/BatteryCharging30';
import BatteryCharging50Icon from '@material-ui/icons/BatteryCharging50';
import BatteryCharging60Icon from '@material-ui/icons/BatteryCharging60';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryUnknownIcon from "@material-ui/icons/BatteryUnknown";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";



let batteryStatus = (level, isCharging) => {



    switch (true) {
        // case level <= 0 :
        //     return <BatteryUnknownIcon className={'InnerIconBattery'} style={{}}/>
        case level <= 20 :
            if(isCharging){
                return <BatteryCharging20Icon className={'InnerIconBattery'} style={{color:red[500]}}/>
            } else {
                return <Battery20Icon className={'InnerIconBattery'} style={{color:red[500]}}/>
            }
        case level <= 30 :
            if (isCharging) {
                return <BatteryCharging30Icon className={'InnerIconBattery'} style={{color:red[500]}}/>
            } else {
                return <Battery30Icon className={'InnerIconBattery'} style={{color:red[500]}}/>
            }
        case level <= 50 :
            if (isCharging) {
                return <BatteryCharging50Icon className={'InnerIconBattery'} style={{color:orange[700]}}/>
            } else {
                return <Battery50Icon className={'InnerIconBattery'} style={{color:orange[700]}}/>
            }
        case level <= 60 :
            if (isCharging) {
                return <BatteryCharging60Icon className={'InnerIconBattery'} style={{color:orange[700]}}/>
            } else {
                return <Battery60Icon className={'InnerIconBattery'} style={{color:orange[700]}}/>
            }
        case level <= 80 :
            if (isCharging) {
                return <BatteryCharging80Icon className={'InnerIconBattery'} style={{color:green[700]}}/>
            } else {
                return <Battery80Icon className={'InnerIconBattery'} style={{color:green[700]}}/>
            }
        case level <= 90 :
            if (isCharging) {
                return <BatteryCharging90Icon className={'InnerIconBattery'} style={{color:green[700]}}/>
            } else {
                return <Battery90Icon className={'InnerIconBattery'} style={{color:green[700]}}/>
            }
        case level <= 100 :
            if (isCharging) {
                return <BatteryChargingFullIcon className={'InnerIconBattery'} style={{color:green[700]}}/>
            } else {
                return <BatteryFullIcon className={'InnerIconBattery'} style={{color:green[700]}}/>
            }

        default:
            return <BatteryUnknownIcon className={'InnerIconBattery'} style={{}}/>
    }
}

export default batteryStatus;