import React, {useEffect} from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import './Contact.css'
import Avatar from "@material-ui/core/Avatar";
import CallIcon from '@material-ui/icons/Call';
import Fab from "@material-ui/core/Fab";
import {List, Map} from 'immutable'

const Contact = (props) => {

    /**
     *Stato utente
     * Busy => in chiamata
     * Online => se Login eseguito
     */
    let state_contact = '#ffa000'
    const isOnline = props.contact.getIn(['isOnline'])
    const isBusy = props.contact.getIn(['isBusy'])
    if (isOnline && !isBusy) {
        state_contact = '#43a047'
    } else if (isOnline && isBusy) {
        state_contact = '#e53935'
    } else if (!isOnline && !isBusy) {
        state_contact = '#ffa000'
    } else if (!isOnline && isBusy) {
        state_contact = '#ffa000'
    }


    return (
        <Card className={'contact'}>
            <div className={'title'}>
                <div className={'state'} style={{borderColor: state_contact}}>
                    <Avatar className={'avatar'} src={null} alt={props.contact.get('name').substring(0, 1)}/>
                </div>

            </div>
            <div className={'body_1'}>
                <Typography className={'name'}>{props.contact.get('name')}</Typography>
                <Typography variant={"subtitle2"} className={'info'}>{props.container.get('title')}</Typography>
                <Typography variant={"subtitle2"}
                            className={'info'}>
                    {props.container.getIn(['groups', props.container.get('groups', List([])).findIndex(val => val.get('id') === props.contact.get('assignedGroup')), 'title'])}
                    {/*{props.container.get('groups', List([])).find(g => g.get('id') === props.contact.get('assignedGroup'), Map({})).get('title', '')}*/}
                </Typography>

                <Fab
                    size="large"
                    className={'call'}
                    onClick={()=> props.call_request(props.contact.get('id'))}
                    onMouseDown={e=> e.preventDefault()}
                    // disabled={props.actCallRequest.keySeq().size > 0}
                >
                    <CallIcon/>
                </Fab>
            </div>


        </Card>

    )


}


export default Contact