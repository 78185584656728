import React from "react";
import './DeviceGroup.css'
import Device from "../Device/Device";
import Typography from "@material-ui/core/Typography";
import ConfigDevice from "../ConfigDevice/ConfigDevice";
import IconTpButton from "../IconTpButton/IconTpButton";
import {withTranslation} from "react-i18next";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

const DeviceGroup = (props) => {
    const {t} = props

    let device = null
    if (props.dev) {
        device = props.dev
            .filter(c => c.get('type') === props.filter || props.filter === 'all')
            // .sortBy(c => c.name)
            // .sortBy(c => !c._isOnline)
            .sort((a, b) => {
                const aName = a.get('name')
                const bName = b.get('name')
                const aOnline = b.get('isOnline')
                if (aName > bName) {
                    if (aOnline) {
                        return -1;
                    }
                    if (!aOnline) {
                        return 1;
                    }
                } else if (aName < bName) {
                    if (aOnline) {
                        return -1;
                    }
                    if (!aOnline) {
                        return -1;
                    }
                } else if (aName === bName) {
                    if (aOnline) {
                        return 0;
                    }
                    if (!aOnline) {
                        return 0;
                    }
                }
                return -1;

            })
            .map((d) => {
                    return <ConfigDevice
                        key={d.get('id')}
                        device={d}
                        updateContacts={(...args) => props.updateContacts(...args)}

                    />
                }
            )
    }

    return (
        <div className={'deviceGroup'}>
            <div className={'title'}>
                <Typography>{props.group.get('title')}</Typography>
                <div className={'addButton'}>
                <IconTpButton onClick={()=>props.onOpenPopUp(props.group.get('id'))} icon={AddCircleOutlineRoundedIcon}
                              tooltip={t('Aggiungi device')}/>
                </div>
            </div>
            <div  className={'devices scroller'}>
                {device}
            </div>
        </div>
    )
}

export default withTranslation()(DeviceGroup);