export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

/**
 * Parsing dell'oggetto query
 * @param query
 */
export const createQueryString = (query) => {
    let q = new URLSearchParams('');
    Object.keys(query).forEach(key => {
        let val = query[key];

        if(val === undefined || val == null) return;

        if(typeof val === "object" && val.length > 0){
            val.forEach(elem => q.append(key, elem))
        }
        else{
            q.append(key, val);
        }

    })
    return '?'+q.toString();
}

export const sendGetRequest = (url, token=null) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, false);
    xhr.setRequestHeader('Content-Type','application/json');
    if(token !== null) {
        xhr.setRequestHeader('Authorization',`Bearer ${token}`);
    }
    xhr.send();
    return JSON.parse(xhr.response);
}

export const sendPostRequest = (url, data, token=null) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, false);
    xhr.setRequestHeader('Content-Type','application/json');

    if(token !== null) xhr.setRequestHeader('Authorization',`Bearer ${token}`);

    xhr.send(JSON.stringify(data));
    return JSON.parse(xhr.response);
}