/**
 * Middleware del cazzo rtc
 * @param socket
 * @returns {function({dispatch?: *}): function(*): function(*=): *}
 */

import {socketEmit, socketUnsubscribe} from "./actions/socketIO";
import * as actionType from "./actions/actions.js";

let senderPeer = null
let receiverPeer = {}
let CandidateQueue = []

let iceCandidate = []

const WebRtcMiddleware = () => ({dispatch, getState}) => next => action => {
    /**
     * {
     *     type: ACTION,
     *     socket: {
     *       subscribe:{
     *          event: 'event',
     *          handle: promise,
     *          responseType: ACTION_ON_EVENT
     *       }
     *       unsubscribe:{
     *          event: 'event'
     *       }
     *       emit:{
     *          event: 'event',
     *          handle: promise,
     *       }
     *     }
     *     data: {}
     * }
     */


    const sendIceCandidate = (subCall, candidate, feed = null) => {
        console.info("send candidate")
        dispatch(socketEmit('candidate', {
            callId: subCall.get('callId'),
            subCallId: subCall.get('id'),
            from: subCall.get('contactFrom'),
            to: subCall.get('contactTo'),
            candidate: candidate,
            feed: feed
        }))
    }

    const sendIceCandidateGroup = (ice) => {
        console.info("send candidate")
        dispatch(socketEmit('candidate', ice))
    }

    const sendOffer = (subCall, description) => {
        console.info("send offer")
        dispatch(socketEmit('offer', {
            callId: subCall.get('callId'),
            subCallId: subCall.get('id'),
            from: subCall.get('contactFrom'),
            to: subCall.get('contactTo'),
            sdp: description.sdp,
            type: description.type,
        }))
        console.debug('offer sended')
    }

    const sendAnswer = (subCall, description, feed = null) => {
        console.info("send answer")
        dispatch(socketEmit('answer', {
            callId: subCall.get('callId'),
            subCallId: subCall.get('id'),
            from: subCall.get('contactFrom'),
            to: subCall.get('contactTo'),
            sdp: description.sdp,
            type: description.type,
            feed: feed
        }))
    }

    const processCandidate = () => {
        CandidateQueue = CandidateQueue.filter((c, i) => {
            if (!c) {
                console.error('nessun dato passato in action.data', c)
                return true
            }

            const cand = c.get('candidate').toJS()

            if (cand.completed) {
                console.info('skip for completed', cand)
                return false
            }

            if (c.get('feed')) {

                if (!receiverPeer[c.get('feed')] || !receiverPeer[c.get('feed')].remoteDescription) {
                    console.error('receiverPeer non valido', c.get('feed'), receiverPeer)
                    return true
                }

                const candidate = new RTCIceCandidate(cand)
                receiverPeer[c.get('feed')].addIceCandidate(candidate)
                    .then(res => console.info('Receiver iceAdded', res))
                    .catch(error => console.error("Errore durante l'inserimento dei candidati", error))
            } else {

                if (!senderPeer || !senderPeer.remoteDescription) {
                    console.error('senderPeer non senza remoteDescription', senderPeer)
                    return true
                }

                const candidate = new RTCIceCandidate(cand)
                senderPeer.addIceCandidate(candidate)
                    .then(res => console.info('Sender iceAdded', res))
                    .catch(error => console.error("Errore durante l'inserimento dei candidati", error))
            }

            return false
        })

    }

    const createNewPeer = (id, feed = null) => {
        const peer = new RTCPeerConnection(getState().calls.config.toJS())
        const subCall = getState().calls.subcalls.get(getState().calls.subcalls.findIndex(s => s.get('id') === id))
        const idLabel = feed ? `[RECEIVER ${feed}]` : `[SENDER ${id}]`

        peer.addEventListener('track', (e) => {
            console.info("track", e.streams)
            dispatch({
                type: actionType.UPDATE_RECEIVER_STREAMS,
                data: {id: feed, stream: e.streams[0]}
            })
        })
        //     else console.warn("No candidate")

        peer.addEventListener('icegatheringstatechange', () => {
            console.info(`${idLabel} - icegatheringstatechange: ${peer.iceGatheringState}`);
            if (peer.iceGatheringState === 'complete') {
                sendIceCandidateGroup(iceCandidate)
                iceCandidate = []
                sendIceCandidate(subCall, 'completed', feed)
            }


            peer.addEventListener('iceconnectionstatechange', () => {
                console.info(`${idLabel} - iceconnectionstatechange: ${peer.iceConnectionState}`);
                if (peer.iceConnectionState === 'disconnected') {
                    // dispatch(HangUpCall())
                    // dispatch(enqueueSnackbar({
                    //     message: '875 - Call non riuscita, errore di comunicazione',
                    //     options: {
                    //         key: new Date().getTime() + Math.random(),
                    //         variant: 'error',
                    //         autoHideDuration: 2000,
                    //     },
                    // }))
                }
            }, false)
        }, false)

        peer.addEventListener('signalingstatechange', () => {
            console.info(`${idLabel} - signalingstatechange: ${peer.signalingState}`);
        }, false)

        peer.onicecandidate = (event) => {
            if (event.candidate) {
                if (iceCandidate.lenght > 5) {
                    sendIceCandidateGroup(iceCandidate)

                    iceCandidate = []
                } else {
                    iceCandidate.push({
                        callId: subCall.get('callId'),
                        subCallId: subCall.get('id'),
                        from: subCall.get('contactFrom'),
                        to: subCall.get('contactTo'),
                        candidate: event.candidate,
                        feed: feed
                    })
                }
            }
        }
        return peer;
    }

    if (action.type === actionType.CREATE_SENDER_PEER) {
        return new Promise((resolve) => {
            console.debug(action)
            senderPeer = createNewPeer(action.data.id)
            console.debug('Peer Created', senderPeer)

            resolve()
        })
    }

    if (action.type === actionType.CREATE_RECEIVER_PEER) {
        return new Promise((resolve) => {
            receiverPeer[action.data.feed] = createNewPeer(action.data.id, action.data.feed)
            resolve()
        })
    }

    if (action.type === actionType.ADD_LOCAL_TRACK) {
        console.info('ADD_LOCAL_TRACK')

        if (!senderPeer) {
            console.error('senderPeer non valido', senderPeer)
            return
        }

        if (!action.data) {
            console.error('nessun dato passato in action.data', action.data)
            return
        }

        return new Promise(resolve => {
            console.debug(senderPeer)
            action.data.getTracks().forEach(track => {
                senderPeer.addTrack(track, action.data)
            })
            dispatch({
                type: actionType.UPDATE_SENDER_STREAM,
                data: action.data
            })
            resolve()
        })
    }

    if (action.type === actionType.MAKE_OFFER) {
        console.info('MAKE_OFFER')

        if (!senderPeer) {
            console.error('senderPeer non valido', senderPeer)
            return
        }

        senderPeer.createOffer()
            .then(sdp => {
                console.info('session description', sdp)
                return senderPeer.setLocalDescription(sdp)
            })
            .then(() => {
                const offer = senderPeer.localDescription;
                const subcalls = getState().calls.subcalls
                sendOffer(subcalls.get(subcalls.findIndex(s => s.get('id') === getState().calls.subcall)), offer)
                dispatch(socketEmit('subcall/update', {id: getState().calls.subcall, state: 2}))
            })
    }

    if (action.type === actionType.HANDLE_ANSWER) {
        console.info('HANDLE_ANSWER', action.data)


        if (!senderPeer) {
            console.error('senderPeer non valido', senderPeer)
            return
        }

        if (!action.data) {
            console.error('nessun dato passato in action.data', action.data)
            return
        }

        const answer = new RTCSessionDescription({sdp: action.data.get('sdp'), type: action.data.get('type')});
        senderPeer.setRemoteDescription(answer)
            .then(() => {
                console.info("Set remote description answer ok")
                dispatch(socketEmit('subcall/update', {id: getState().calls.subcall, state: 3}))
                processCandidate()
            })
            .catch(error => {
                dispatch(socketEmit('subcall/update', {id: getState().calls.subcall, state: -1}))
                console.error("error set remote description", error)
            })
    }


    if (action.type === actionType.HANDLE_CANDIDATE) {
        console.info('HANDLE_CANDIDATE', action.data)
        CandidateQueue.push(action.data)
        processCandidate()
    }

    if (action.type === actionType.HANDLE_OFFER) {
        console.info('HANDLE_OFFER', action.data)

        if (!action.data.get('feed')) {
            console.error("Feed not provided")
            return
        }

        const peer = createNewPeer(action.data.get('subCallId'), action.data.get('feed'))
        const offer = new RTCSessionDescription({sdp: action.data.get('sdp'), type: action.data.get('type')});
        const subCall = getState().calls.subcalls.get(getState().calls.subcalls.findIndex(s => s.get('id') === getState().calls.subcall))

        peer.setRemoteDescription(offer)
            .then(() => peer.createAnswer())
            .then((sdp) => peer.setLocalDescription(sdp))
            .then(() => {
                sendAnswer(subCall, peer.localDescription, action.data.get('feed'))
                receiverPeer[action.data.get('feed')] = peer
                processCandidate()
            })
            .catch(error => console.error("error set remote description", error))

    }

    if (action.type === actionType.HANG_UP) {
        console.debug('try close senderPeer', senderPeer)
        if (senderPeer) {
            senderPeer.close()
            console.debug('close senderPeer')
        }
        console.debug('try close receiverPeer', receiverPeer)
        if (receiverPeer) {
            Object.keys(receiverPeer).forEach(k => receiverPeer[k].close())
            console.debug('close receiverPeer')
        }
        console.debug('try close senderStreams', getState().calls.senderStreams)
        if (getState().calls.senderStreams) {
            getState().calls.senderStreams.getTracks().forEach(t => t.stop())
            console.debug('close senderStreams')
        }
        console.debug('try close receiverStreams', getState().calls.receiverStreams)
        if (getState().calls.receiverStreams) {
            Object.keys(getState().calls.receiverStreams).forEach(st => getState().calls.receiverStreams[st].getTracks().forEach(t => t.stop()))
            console.debug('close receiverStreams')
        }
        console.debug('try close subcalls', getState().calls.subcalls)
        if (getState().calls.subcalls) {
            getState().calls.subcalls.forEach(s => dispatch(socketUnsubscribe(`subcall/${s.get('id')}/update`)))
            console.debug('close subcalls')
        }
        dispatch(socketUnsubscribe(`call/${getState().calls.call_request.get('id')}/update`))
        senderPeer = null
        receiverPeer = []
    }

    return next(action);
};

export default WebRtcMiddleware;