import io from 'socket.io-client';

export default class socketAPI {
    socket;

    connect() {
        this.socket = io(process.env.REACT_APP_SOCKET_IO_URL);
        return new Promise((resolve, reject) => {
            this.socket.on('connect', () => resolve());
            this.socket.on('error', (error) => reject(error));
            this.socket.on('connect_error', (error) => console.error('connect_error', error));
            this.socket.on('connect_timeout', (error) => console.error('connect_timeout', error));
            this.socket.on('reconnection', (error) => console.error('reconnection', error));
            this.socket.on('reconnect_error', (error) => console.error('reconnect_error', error));
            this.socket.on('reconnect_failed', (error) => console.error('reconnect_failed', error));

        });
    }

    disconnect() {
        return new Promise((resolve) => {
            this.socket.disconnect(() => {
                this.socket = null;
                resolve();
            });
        });
    }

    emit(event, data) {
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket connection.');

            return this.socket.emit(event, data, (response) => {
                   return resolve(response);
            });
        });
    }

    on(event, fun) {
        // No promise is needed here, but we're expecting one in the middleware.
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket connection.');

            this.socket.on(event, fun);
            resolve();
        });
    }


    removeListener(event) {
        // No promise is needed here, but we're expecting one in the middleware.
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('No socket connection.');

            this.socket.off(event);
            resolve();
        });
    }


}
